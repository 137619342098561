import { Modal } from "react-bootstrap";
function OrdersETA(props) {
    return (
        <Modal class="modalform" size="lg" class="modal-dialog modal-dialog-centered modal-lg" style={{ 'width': '1000px;', 'padding-top': '150px' }} fullscreen='true' show={props.values.show} onHide={props.values.handleClose}>
            <div class="modal-content">
                <Modal.Header closeButton>
                    <Modal.Title>Change ETA</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="form-group">
                        <label>New ETA (Minutes)</label>
                        <input type="number" class="form-control" value={props.values.eta} onChange={(e) => {
                            var etaVal = e.target.value;
                            if (etaVal.length > 4 || etaVal <= 0) {
                                alert("Invalid ETA!!");
                                props.values.setEta("");
                                return false;
                            }
                            props.values.setEta(etaVal);
                        }
                        } />
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
}
export default OrdersETA;