import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function BannerModal(props) {
    var propValues = props.values;
    return (
        <div className="test">
            <Modal className="globle-popup-width" class="modalform modal-dialog modal-dialog-centered modal-lg" size="lg" style={{ 'width': '1000px;', 'padding-top': '120px' }} fullscreen='true' show={propValues.show} onHide={propValues.handleClose}>
                <div class="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title>{propValues.labels}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="modal-body">
                            <section class="staff_profile_sec">
                                <div class="row">
                                    <div class="col-md-4"><div class="staff_profile_pic_box">
                                        <div class="staff_profile_img">   {
                                            (propValues.images == '') ?
                                                <img src='./img-placeholder.png' alt="" /> : <img src={propValues.images} alt="" />
                                        }</div>
                                        <label class="custom-file-upload">
                                            <input type="file" value={propValues.banners_images} onChange={e => propValues._onChangeImage(e.target.files[0])} />
                                            Upload Image
                                        </label>
                                        {
                                            (propValues.viewImageError) ?
                                                <small></small> : <><small style={{ color: 'red' }}>{propValues.view_image_error}</small></>
                                        }</div></div>
                                    <div class="col-md-8">
                                        <div class="staff_profile_form">
                                            <form action="">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Banner Name</label>
                                                            <input type="text" class="form-control" value={propValues.name} onChange={e => propValues._onChangeText('name', e.target.value)} />
                                                            {
                                                                (propValues.viewNameError) ?
                                                                    null : <><small style={{ color: 'red' }}>{propValues.view_name_error}</small></>}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group" >
                                                            <label>Schedule</label>
                                                            <DatePicker
                                                                onKeyDown={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                                minDate={new Date()}
                                                                className="form-control"
                                                                selectsRange={true}
                                                                startDate={propValues.startDate}
                                                                endDate={propValues.endDate}

                                                                onChange={(update) => {
                                                                    propValues.setDateRange(update);
                                                                    propValues.setSheduledError(true)
                                                                    propValues.sheduled_error("")
                                                                }}
                                                            />
                                                            {
                                                                (propValues.viewSheduledError) ?
                                                                    null : <><small style={{ color: 'red' }}>{propValues.view_sheduled_error}</small></>}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>{localStorage.getItem("multi_restaurant")=='true' ? "Restaurant" : "Location"}</label>
                                                            <select class="form-control"
                                                                value={propValues.locationId}
                                                                placeholder={propValues.multi_restaurant =='true' ? 'Select Restaurant' : 'Select Location'}
                                                                onChange={e => propValues._onChangeText('locationId', e.target.value)}
                                                            >

                                                                {propValues.locations.length > 0
                                                                    ? propValues.locations?.map((item, index) => {
                                                                        return (
                                                                            <option selected={item.id == item.locationId ? true : false} value={item.id} >{item.name}</option>
                                                                        )
                                                                    })
                                                                    : null
                                                                }
                                                            </select>
                                                            {
                                                                (propValues.viewLocationError) ?
                                                                    null : <><small style={{ color: 'red' }}>{propValues.view_location_error}</small></>}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>Description</label>
                                                            <textarea cols="4" rows="4" value={propValues.description} class="form-control" onChange={e => propValues._onChangeText('description', e.target.value)}></textarea>
                                                            {
                                                                (propValues.viewDescriptionError) ?
                                                                    null : <><small style={{ color: 'red' }}>{propValues.view_description_error}</small></>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div></div>
                                </div>
                            </section>
                        </div>
                    </Modal.Body>
                    <Modal.Footer >
                        <div class="col-md-12">
                            <div class="form-group mb-0 staff_profile_btn_group">
                                <button type="submit" class="btn_sty_1" style={{ "width": '110px;' }} onClick={() => propValues.onSubmit()}>Submit</button>
                            </div>
                        </div>
                    </Modal.Footer>
                </div>
            </Modal>
        </div>
    );
}
export default BannerModal;