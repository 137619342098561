function Statistics(props) {
    return (
            <div class="row">
                <div class="col-md-3">
                    <div class="stat_box">
                        <div class="icon_box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29.95" height="29.6" viewBox="0 0 29.95 29.6">
                                <g id="icon" transform="translate(0 -2.992)">
                                    <g id="Group_2679" data-name="Group 2679" transform="translate(0 2.992)">
                                        <g id="Group_2678" data-name="Group 2678" transform="translate(0 0)">
                                            <path id="Path_99" data-name="Path 99" d="M18.719,8.215V8.207l5.6,2.51,5.627-2.11L14.975,2.992,0,8.608l14.975,5.616,5.6-2.1-5.6-2.31V9.806Z" transform="translate(0 -2.992)" fill="#fe8958" />
                                        </g>
                                    </g>
                                    <g id="Group_2681" data-name="Group 2681" transform="translate(0 10.48)">
                                        <g id="Group_2680" data-name="Group 2680" transform="translate(0 0)">
                                            <path id="Path_100" data-name="Path 100" d="M0,130.992v16.847L14.039,153.1V136.258ZM7.488,147.9l-3.744-1.4v-2l3.744,1.4Z" transform="translate(0 -130.992)" fill="#fe8958" />
                                        </g>
                                    </g>
                                    <g id="Group_2683" data-name="Group 2683" transform="translate(15.911 10.48)">
                                        <g id="Group_2682" data-name="Group 2682" transform="translate(0 0)">
                                            <path id="Path_101" data-name="Path 101" d="M280.423,133.1v4.689l-3.744,1.4V134.5L272,136.258V153.1l14.039-5.266V130.992Z" transform="translate(-272 -130.992)" fill="#fe8958" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="stat_info">
                            <h2>{props.values.pending_count}</h2>
                            <h3>Pending Orders</h3>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="stat_box">
                        <div class="icon_box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29.95" height="29.6" viewBox="0 0 29.95 29.6">
                                <g id="icon" transform="translate(0 -2.992)">
                                    <g id="Group_2679" data-name="Group 2679" transform="translate(0 2.992)">
                                        <g id="Group_2678" data-name="Group 2678" transform="translate(0 0)">
                                            <path id="Path_99" data-name="Path 99" d="M18.719,8.215V8.207l5.6,2.51,5.627-2.11L14.975,2.992,0,8.608l14.975,5.616,5.6-2.1-5.6-2.31V9.806Z" transform="translate(0 -2.992)" fill="#42cc9f" />
                                        </g>
                                    </g>
                                    <g id="Group_2681" data-name="Group 2681" transform="translate(0 10.48)">
                                        <g id="Group_2680" data-name="Group 2680" transform="translate(0 0)">
                                            <path id="Path_100" data-name="Path 100" d="M0,130.992v16.847L14.039,153.1V136.258ZM7.488,147.9l-3.744-1.4v-2l3.744,1.4Z" transform="translate(0 -130.992)" fill="#42cc9f" />
                                        </g>
                                    </g>
                                    <g id="Group_2683" data-name="Group 2683" transform="translate(15.911 10.48)">
                                        <g id="Group_2682" data-name="Group 2682" transform="translate(0 0)">
                                            <path id="Path_101" data-name="Path 101" d="M280.423,133.1v4.689l-3.744,1.4V134.5L272,136.258V153.1l14.039-5.266V130.992Z" transform="translate(-272 -130.992)" fill="#42cc9f" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="stat_info">
                            <h2>
                            {props.values.accpected_count}
                            </h2>
                            <h3>Accepted Orders</h3>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="stat_box">
                        <div class="icon_box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29.95" height="29.6" viewBox="0 0 29.95 29.6">
                                <g id="icon" transform="translate(0 -2.992)">
                                    <g id="Group_2679" data-name="Group 2679" transform="translate(0 2.992)">
                                        <g id="Group_2678" data-name="Group 2678" transform="translate(0 0)">
                                            <path id="Path_99" data-name="Path 99" d="M18.719,8.215V8.207l5.6,2.51,5.627-2.11L14.975,2.992,0,8.608l14.975,5.616,5.6-2.1-5.6-2.31V9.806Z" transform="translate(0 -2.992)" fill="#4eafeb" />
                                        </g>
                                    </g>
                                    <g id="Group_2681" data-name="Group 2681" transform="translate(0 10.48)">
                                        <g id="Group_2680" data-name="Group 2680" transform="translate(0 0)">
                                            <path id="Path_100" data-name="Path 100" d="M0,130.992v16.847L14.039,153.1V136.258ZM7.488,147.9l-3.744-1.4v-2l3.744,1.4Z" transform="translate(0 -130.992)" fill="#4eafeb" />
                                        </g>
                                    </g>
                                    <g id="Group_2683" data-name="Group 2683" transform="translate(15.911 10.48)">
                                        <g id="Group_2682" data-name="Group 2682" transform="translate(0 0)">
                                            <path id="Path_101" data-name="Path 101" d="M280.423,133.1v4.689l-3.744,1.4V134.5L272,136.258V153.1l14.039-5.266V130.992Z" transform="translate(-272 -130.992)" fill="#4eafeb" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="stat_info">
                        <h2> {props.values.completed_count}</h2>
                            <h3>Completed Orders</h3>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="stat_box">
                        <div class="icon_box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.899" height="28.933" viewBox="0 0 25.899 28.933">
                                <g id="user" transform="translate(-24.165)">
                                    <g id="Group_2685" data-name="Group 2685" transform="translate(29.604)">
                                        <g id="Group_2684" data-name="Group 2684">
                                            <path id="Path_102" data-name="Path 102" d="M118.3,0a7.512,7.512,0,1,0,7.512,7.512A7.532,7.532,0,0,0,118.3,0Z" transform="translate(-110.791)" fill="#f55184" />
                                        </g>
                                    </g>
                                    <g id="Group_2687" data-name="Group 2687" transform="translate(24.165 15.562)">
                                        <g id="Group_2686" data-name="Group 2686" transform="translate(0)">
                                            <path id="Path_103" data-name="Path 103" d="M50.008,253.306a7,7,0,0,0-.754-1.378,9.338,9.338,0,0,0-6.462-4.068,1.383,1.383,0,0,0-.951.23,8.008,8.008,0,0,1-9.448,0,1.231,1.231,0,0,0-.951-.23,9.27,9.27,0,0,0-6.462,4.068,8.075,8.075,0,0,0-.754,1.378.7.7,0,0,0,.033.623,13.087,13.087,0,0,0,.886,1.312,12.467,12.467,0,0,0,1.509,1.706,19.656,19.656,0,0,0,1.509,1.312,14.959,14.959,0,0,0,17.845,0,14.417,14.417,0,0,0,1.509-1.312,15.145,15.145,0,0,0,1.509-1.706,11.5,11.5,0,0,0,.886-1.312A.56.56,0,0,0,50.008,253.306Z" transform="translate(-24.165 -247.841)" fill="#f55184" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="stat_info">
                        <h2>{props.values.customer_count}</h2>
                            <h3>Total Customers</h3>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="stat_box">
                        <div class="icon_box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="31.201" height="29.951" viewBox="0 0 31.201 29.951">
                                <g id="wallet-filled-money-tool" transform="translate(0 -17.84)">
                                    <g id="Group_2688" data-name="Group 2688" transform="translate(0 17.84)">
                                        <path id="Path_104" data-name="Path 104" d="M161.2,23.542l12.438-3.4-.659-1.331a1.753,1.753,0,0,0-2.347-.792L159.5,23.542Z" transform="translate(-153.912 -17.84)" fill="#9969fe" />
                                        <path id="Path_105" data-name="Path 105" d="M334.309,86.46a1.751,1.751,0,0,0-.462.063l-2.922.8L322,89.757h14.544l-.547-2A1.749,1.749,0,0,0,334.309,86.46Z" transform="translate(-310.718 -84.056)" fill="#9969fe" />
                                        <path id="Path_106" data-name="Path 106" d="M27.651,215.56H1.755a1.754,1.754,0,0,0-1.692,1.293,1.813,1.813,0,0,0-.063.459v19.519a1.752,1.752,0,0,0,1.752,1.752h25.9a1.752,1.752,0,0,0,1.752-1.752v-4.888H19a2.98,2.98,0,0,1-2.978-2.978v-3.9A2.974,2.974,0,0,1,19,222.094H29.4v-4.783A1.747,1.747,0,0,0,27.651,215.56Z" transform="translate(0 -208.632)" fill="#9969fe" />
                                        <path id="Path_107" data-name="Path 107" d="M505.777,437.22a1.751,1.751,0,0,0-.613-.364,1.787,1.787,0,0,0-.568-.095H494.152a1.752,1.752,0,0,0-1.752,1.752v3.9a1.752,1.752,0,0,0,1.752,1.752H504.6a1.786,1.786,0,0,0,.568-.095,1.7,1.7,0,0,0,.613-.364,1.746,1.746,0,0,0,.571-1.293v-3.9A1.746,1.746,0,0,0,505.777,437.22Zm-7.967,3.591a.876.876,0,0,1-.876.876h-.582a.876.876,0,0,1-.876-.876v-.582a.858.858,0,0,1,.336-.687.885.885,0,0,1,.54-.189h.582a.876.876,0,0,1,.876.876v.582Z" transform="translate(-475.148 -422.083)" fill="#9969fe" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="stat_info">
                        <h2>{props.values.TodaySalesTotal}</h2>
                            <h3>Today’s Sale</h3>
                        </div>
                    </div>
                </div>
                {
                    (localStorage.getItem("role") == "businessowner") ?
                        <div class="col-md-3">
                            <div class="stat_box">
                                <div class="icon_box">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24.963" height="32.771" viewBox="0 0 24.963 32.771">
                                        <g id="t_location" transform="translate(-61)">
                                            <g id="Group_6956" data-name="Group 6956" transform="translate(61)">
                                                <path id="Path_3632" data-name="Path 3632" d="M73.481,0A12.5,12.5,0,0,0,61,12.481a12.369,12.369,0,0,0,2.49,7.482l9.222,12.421c.023.03.058.042.084.068a.929.929,0,0,0,1.454-.068c2.656-3.543,7.523-10.161,9.395-12.657v0l.012-.015A12.482,12.482,0,0,0,73.481,0Zm0,19.215a6.734,6.734,0,1,1,6.734-6.734A6.751,6.751,0,0,1,73.481,19.215Z" transform="translate(-61)" fill="#e1b12c" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div class="stat_info">
                                <h2>{props.values.locations_count}</h2>
                                <h3>Total {localStorage.getItem("multi_restaurant") == 'true' ? 'Restaurant' : 'Location'}</h3>
                                </div>
                            </div>
                        </div> : ''
                }
                {
                    (localStorage.getItem("role") == "businessowner") ?
                        <div class="col-md-3">
                            <div class="stat_box">
                                <div class="icon_box">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25.899" height="28.933" viewBox="0 0 25.899 28.933">
                                        <g id="user" transform="translate(-24.165)">
                                            <g id="Group_2685" data-name="Group 2685" transform="translate(29.604)">
                                                <g id="Group_2684" data-name="Group 2684">
                                                    <path id="Path_102" data-name="Path 102" d="M118.3,0a7.512,7.512,0,1,0,7.512,7.512A7.532,7.532,0,0,0,118.3,0Z" transform="translate(-110.791)" fill="#f55184" />
                                                </g>
                                            </g>
                                            <g id="Group_2687" data-name="Group 2687" transform="translate(24.165 15.562)">
                                                <g id="Group_2686" data-name="Group 2686" transform="translate(0)">
                                                    <path id="Path_103" data-name="Path 103" d="M50.008,253.306a7,7,0,0,0-.754-1.378,9.338,9.338,0,0,0-6.462-4.068,1.383,1.383,0,0,0-.951.23,8.008,8.008,0,0,1-9.448,0,1.231,1.231,0,0,0-.951-.23,9.27,9.27,0,0,0-6.462,4.068,8.075,8.075,0,0,0-.754,1.378.7.7,0,0,0,.033.623,13.087,13.087,0,0,0,.886,1.312,12.467,12.467,0,0,0,1.509,1.706,19.656,19.656,0,0,0,1.509,1.312,14.959,14.959,0,0,0,17.845,0,14.417,14.417,0,0,0,1.509-1.312,15.145,15.145,0,0,0,1.509-1.706,11.5,11.5,0,0,0,.886-1.312A.56.56,0,0,0,50.008,253.306Z" transform="translate(-24.165 -247.841)" fill="#f55184" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div class="stat_info">
                                <h2>{props.values.active_drivers_count}</h2>
                                    <h3>Approved Drivers</h3>
                                </div>
                            </div>
                        </div> : ''
                }
                <div class="col-md-3">
                    <div class="stat_box">
                        <div class="icon_box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29.95" height="29.6" viewBox="0 0 29.95 29.6">
                                <g id="icon" transform="translate(0 -2.992)">
                                    <g id="Group_2679" data-name="Group 2679" transform="translate(0 2.992)">
                                        <g id="Group_2678" data-name="Group 2678" transform="translate(0 0)">
                                            <path id="Path_99" data-name="Path 99" d="M18.719,8.215V8.207l5.6,2.51,5.627-2.11L14.975,2.992,0,8.608l14.975,5.616,5.6-2.1-5.6-2.31V9.806Z" transform="translate(0 -2.992)" fill="#4eafeb" />
                                        </g>
                                    </g>
                                    <g id="Group_2681" data-name="Group 2681" transform="translate(0 10.48)">
                                        <g id="Group_2680" data-name="Group 2680" transform="translate(0 0)">
                                            <path id="Path_100" data-name="Path 100" d="M0,130.992v16.847L14.039,153.1V136.258ZM7.488,147.9l-3.744-1.4v-2l3.744,1.4Z" transform="translate(0 -130.992)" fill="#4eafeb" />
                                        </g>
                                    </g>
                                    <g id="Group_2683" data-name="Group 2683" transform="translate(15.911 10.48)">
                                        <g id="Group_2682" data-name="Group 2682" transform="translate(0 0)">
                                            <path id="Path_101" data-name="Path 101" d="M280.423,133.1v4.689l-3.744,1.4V134.5L272,136.258V153.1l14.039-5.266V130.992Z" transform="translate(-272 -130.992)" fill="#4eafeb" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="stat_info">
                        <h2>{props.values.active_products_count}</h2>
                            <h3>Total Products</h3>
                        </div>
                    </div>
                </div>
            </div>
    );
}
export default Statistics;