import React, { useState, useEffect } from "react";
import Header from "./header"
import Sidebar from "./menu"
import * as Url from '../../src/services/ServiceConstant';
import apiRequest from "../services/ServiceRequest";
import "../assets/css/pagination.css";
import DatePicker from "react-datepicker";
import 'react-toastify/dist/ReactToastify.css';
import { setLocalizationSettings, LoaderGif } from '../utils/common.js';
import { Modal } from 'react-bootstrap';
import MessagesList from "../components/list/messages";
const Messages = (props) => {
    const printRef = React.useRef();
    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState("");
    const [transaction_data, setTransactionsData] = useState("");
    const [view_show_msg, show_mesage] = useState("");
    const [headers, setHeaders] = useState("");
    const [data, setData] = useState("");
    const [delivery_charge, setDeliveryCharge] = useState("");
    const [global_date, setGlobalDateFormat] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    var range_data = []
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(20);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const [driverInfo, setdriverInfo] = useState([]);
    const [order_detail, setOrderDetail] = useState(false);
    const [show, setShow] = useState(false);
    const [orderId, setOrderId] = useState("");
    const [item_total, setItemTotal] = useState("");
    const [order_total, setOrderTotal] = useState("");
    const [tax_total, setTaxTotal] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address_name, setAddressName] = useState("");
    const [type, setType] = useState("");
    const [orderno, setOrderNo] = useState("");
    const [sheduled_date, sheduledDate] = useState("");
    const [ordered_date, orderedDate] = useState("");
    const [instructions, setInstructions] = useState("");
    const [note, setNote] = useState("");
    const [statusus, setStatusus] = useState("");
    const [products, setProducts] = useState("");
    const [eta, setEta] = useState();
    const [global_currency, setGlobalCurrency] = useState('');
    const [corporate_address, setCorporateAddess] = useState('');
    const [support_email, setSupportEmail] = useState('');
    const [support_phone, setSupportPhone] = useState('');
    const [business_name, setBusinessName] = useState('');
    const [res_name, setRestaurantName] = useState('');
    const [res_address, setRestaurantAddress] = useState('');
    const [order_type, setOrderType] = useState('');
    const [offer_amount, setOfferAmount] = useState("");
    const [messages, setMessages] = useState([]);
  const [filtered_messages, setFilteredMessages] = useState([]);
  const [search_subject, setSearchSubject] = useState("");
    const handleClick = (event) => {
      setcurrentPage(Number(event.target.id));
    };
    useEffect(async () => {
        let projectSettings = await apiRequest([], Url.BASE_URL + '/projectSettings', 'get');
        setCorporateAddess(projectSettings.data?.extracted[0]?.corporate_address);
        setSupportEmail(projectSettings.data?.extracted[0]?.support_email);
        setSupportPhone(projectSettings.data?.extracted[0]?.support_phone);
        setBusinessName(projectSettings.data?.extracted[0]?.business_name);
    }, []);
    const pages = [];
  for (let i = 1; i <= Math.ceil(filtered_messages.length / itemsPerPage); i++) {
      pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filtered_messages.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map((number) => {
      if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
        return (
          <li
            key={number}
            id={number}
            onClick={handleClick}
            className={currentPage == number ? "active" : null}
          >
            {number}
          </li>
        );
      } else {
        return null;
      }
    });
  
    const handleOrderDetailClose = async () => {
        setOrderDetail(false)
  }
  
    const renderData = (data) => {
        return (
            <MessagesList
                values={{
                    data: data,
                    global_date: global_date,
                    filterDateRange: filterDateRange,
                    startDate: startDate,
                    endDate: endDate,
                    dateRange: dateRange,
                }}
            />
        );
    };
    useEffect(async () => {
        var localSettings = await setLocalizationSettings();
        await setGlobalDateFormat(localSettings?.[0]);
        await setGlobalCurrency(localSettings?.[1]);
        getMessages();
    }, []);
    const getMessages = async() => {
        let endpoint = Url.BASE_URL + '/ContactQueries?filter={"order":"id desc"}'
        var get_messages = await apiRequest([], endpoint, 'get')
        if (get_messages == null || get_messages == undefined || get_messages.length <= 0 || get_messages.data.length <= 0) {
            show_mesage("No records found")
            setLoading(false);
        }
        else {
          setMessages(get_messages.data);
          setFilteredMessages(get_messages.data);
          setLoading(false);
        }
    }
    const handleCallback = async (childData) => {

    }
    const filterDateRange = async (daterange) => {
        let mindate = new Date(daterange[0]);
        let maxdate = new Date(daterange[1]);
        mindate.setHours(0, 0, 0, 0)
        maxdate.setHours(0, 0, 0, 0);
      let ORDERS = [...filtered_messages]
        ORDERS.forEach(function (p, i) {
          if (p.date_of_contact != null && p.date_of_contact != undefined) {
                let d = new Date(p.date_of_contact);
                d.setHours(0, 0, 0, 0);
                if (d >= mindate) {
                    if (maxdate >= d) {
                        range_data.push(p)
                    }
                }
            }
        })
        setFilteredMessages(range_data);
    }
    const handleNextbtn = () => {
        setcurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
          setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
      };
      const handlePrevbtn = () => {
        setcurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
          setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
      };
      let pageIncrementBtn = null;
      if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
      }
      let pageDecrementBtn = null;
      if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
      }
      const handleLoadMore = () => {
        setitemsPerPage(itemsPerPage + 5);
  };
  const filterBySubject = async (e) => {
    const searchString = e.target.value.toLowerCase();
    setSearchSubject(searchString);
    if (searchString.length > 0) {
      const filterMessages = filtered_messages.filter(item => {
        if (item?.subject == undefined) {
          return (
            item?.subject?.toLowerCase()?.includes(searchString) || item?.subject?.includes(searchString) || item?.name?.toLowerCase()?.includes(searchString) || item?.message?.toLowerCase()?.includes(searchString)
          )
        } else {
          return (
            item?.subject?.toLowerCase()?.includes(searchString) || item?.subject?.includes(searchString) || item?.name?.toLowerCase()?.includes(searchString) || item?.message?.toLowerCase()?.includes(searchString)
          )
        }
      });
      if (filterMessages) {
        setFilteredMessages(filterMessages);
        setcurrentPage(1);
      } else {
        window.location.reload();
      }
    } else {
      window.location.reload();
    }
  }
   
     
    return (
        <div>
            <html lang="en">
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                <div class="sticky">
                    <Header parentCallback={handleCallback} />
                </div>
                <body>
                    <div class="middle_block">
                        <Sidebar tabvalue='staff' />
                        <div class="main_content">
                            <div class="container-fluid">
                                <div class="manage_staff">
                                    <div class="sec_title transactions_report_title">
                      <h2 class="head_sty1">Messages</h2>
                      
                    </div>
                    
                        <div class="row">
                          <div class="col-md-9">
                            <div class="order_search">
                          <input type="text" value={search_subject} onChange={(e) => filterBySubject(e)} class="form-control" placeholder="Search by Subject, Name, Message..." />
                              <a href="#"><i class="icofont-search-1"></i></a>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="s_date_wrap form-control" style={{
                              alignSelf: 'center', width: '100%',
                              display: 'flex',
                              justifyContent: 'spaceBetween',
                              alignItems: 'center'
                            }} >
                          <DatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            className='order-date-picker-css'
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                              console.log(update);
                              if (update[0] == null && update[1] == null) {
                                console.log("one");
                                window.location.reload();
                              }
                              setDateRange(update);
                              var strToDate = new Date(dateRange[0]);
                              if (update.length == 2 && update[0] != null && update[1] != null) {
                                console.log("two");
                                filterDateRange(update)
                                return
                              }
                            }}
                          />
                              <i class="icofont-ui-calendar calendar_icon" style={{ paddingLeft: 10, "margin": "-4px -130px 7px 5px" }} htmlFor='fromdate'></i>
                            </div>
                          </div>
                          

                        </div><br></br>
                     
                                    {(loading == false) ? renderData(currentItems) : <img className="loading" src={LoaderGif()}></img>}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
            <ul className="pageNumbers">
        <li onClick={handlePrevbtn}
            className={currentPage == pages[0] ? "disallow" : "allow"}>
            {"<"}
        </li>
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}
        <li onClick={handleNextbtn}
            className={currentPage == pages[pages.length - 1] ? "disallow" : "allow"}>
            {">"}
        </li>
      </ul>
        </div>
    )
}
export default Messages;