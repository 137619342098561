import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const OrderFilters = (props) => {
    return (
        <div class="order_top_block">
            <div class="row">
                <div class="col-md-3">
                    <div class="order_search">
                        <input type="text" value={props.values.serachedname} onChange={props.values.filter} class="form-control" placeholder="Search by Name or ID..." />
                        <a href="#"><i class="icofont-search-1"></i></a>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="s_date_wrap form-control" style={{
                        alignSelf: 'center', width: '100%',
                        display: 'flex',
                        justifyContent: 'spaceBetween',
                        alignItems: 'center'
                    }} >
                        <DatePicker
                            className='order-date-picker-css'
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            placeholder="Select Date Range"
                            selectsRange={true}
                            startDate={props.values.startDate}
                            endDate={props.values.endDate}
                            onChange={(update) => {
                                console.log(update);
                                if (update[0]==null && update[1]==null) {
                                    console.log("one");
                                    window.location.reload();
                                }
                                props.values.setDateRange(update);
                                var strToDate = new Date(props.values.dateRange[0]);
                                if (update.length == 2 && update[0] != null && update[1] != null) {
                                    console.log("two");
                                    props.values.filterDateRange(update)
                                    return
                                }
                            }}
                        />
                        <i class="icofont-ui-calendar calendar_icon" style={{ paddingLeft: 10, "margin": "-4px -130px 7px 5px" }} htmlFor='fromdate'></i>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="order_sort">
                        <select name="" id="" onChange={props.values.filterByStatus} class="form-control">
                            <option value="all">All Orders</option>
                            <option value="Pending">Pending</option>
                            <option value="Accepted"> Accepted</option>
                            <option value="Cancelled"> Cancelled</option>
                            <option value="Preparing"> Preparing</option>
                            <option value="Prepared"> Prepared</option>
                            <option value="Picked Up"> Picked Up</option>
                            {/* <option value="On The Way"> On The Way</option> */}
                            <option value="Delivered">Delivered</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <button style={{ "padding": "8px 20px" }} onClick={() => props.values.setManualOrderClose(true)} class="btn_sty_1">Add</button>
                </div>
            </div>
        </div>
    );
}
export default OrderFilters;