import { useState, useEffect } from "react";
import Header from "./header"
import Sidebar from "./menu"
import * as URL from '../../src/services/ServiceConstant';
import apiRequest from "../services/ServiceRequest";
import "../assets/css/pagination.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validateName } from '../helpers/validation';
import "react-datepicker/dist/react-datepicker.css";
import BannersList from "./list/banners";
import { setBannerSetting, dynamicStylesheet } from '../utils/common.js';
import { BannersStatusFilter } from "./filters/banners";
import BannerModal from "./models/banner";
var ss1
var ss2
var banners_images = []
var update = ''
var method = ''
var endpoints = ''
const Banners = (props) => {
    toast.configure()
    const [edits, setEdit] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [get_data, setBanners] = useState("");
    const [view_show_msg, show_mesage] = useState("");
    const [show, setShow] = useState(false);
    const [datepick, setDatePicker] = useState(false);
    const [images, setImage] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [expiry, setExpiryDate] = useState("");
    const [start, setStartDate] = useState("");
    const [locationId, setLocationId] = useState("");
    const [highlightColor, setHighLightColor] = useState('');
    const [global_date, setGlobalDateFormat] = useState('');
    const [locations, setLocationData] = useState("");
    const [combinedDate, setCombinedDate] = useState("");
    const [labels, labelText] = useState("");
    const [view_name_error, name_error] = useState("");
    const [enabless, setEnables] = useState(false);
    const [view_description_error, description_error] = useState("");
    const [view_image_error, image_error] = useState("");
    const [view_location_error, location_error] = useState("");
    const [view_start_error, start_error] = useState("");
    const [view_sheduled_error, sheduled_error] = useState("");
    const [loading, setLoading] = useState(true);
    const [ID, setID] = useState("");
    const [filterbanners, setFilterBanners] = useState([]);
    const [viewNameError, setNameError] = useState(false);
    const [viewDescriptionError, setDescriptionError] = useState(false);
    const [viewLocationError, setLocationError] = useState(false);
    const [viewImageError, setImageError] = useState(false);
    const [viewStartError, setStartError] = useState(false);
    const [viewSheduledError, setSheduledError] = useState(false);
    const _onChangeText = (type, text) => {
        if (type === 'name') {
            if (text !== '') {
                setName(text)
                const _ValidateFirstName = validateName(text, ' Name'); 
                if (_ValidateFirstName['status']) {
                    name_error('')
                    setNameError(true)
                }
                else {
                    name_error(_ValidateFirstName['error'])
                    setNameError(false)
                }
            }
            else if (text === '' || text === undefined || text === null) {
                setName(text)
                name_error('Please enter Name')
                setNameError(false)
            }
        }
        if (type === 'description') {
            if (text !== '') {
                setDescription(text)
                description_error('')
                setDescriptionError(true)
            }
            else if (text === '' || text === undefined || text === null) {
                setDescription(text)
                description_error('Please enter Description')
                setDescriptionError(false)
            }
        }
        if (type === 'locationId') {
            if (text !== '') {
                setLocationId(text)
                location_error('')
                setLocationError(true)
            }
            else if (text === '' || text === undefined || text === null) {
                setLocationId(text)
                location_error('Please enter Description')
                setLocationError(false)
            }
        }
    }
    const onEdit = (edit) => {
        labelText("Edit Banner")
        setEdit(true)
        if (edit.location != "" && edit.location != null && edit.location != undefined) {
            setLocationId(edit.location.id)
        } else {
            setLocationId(edit.locationId);
        }
        if (edit.id != "") {
            setID(edit.id)
        }
        if (edit.name != "") {
            setName(edit.name)
        }
        if (edit.description != "") {
            setDescription(edit.description)
        }
        if (edit.start_date != "") {
            var d = new Date(edit.start_date).toLocaleDateString();
            setStartDate(d.toString())
        }
        if (edit.expiry != "") {
            var d = new Date(edit.expiry);
            var n = d.toUTCString();
            setExpiryDate(n)
        }
        if (edit.description != "") {
            setDescription(edit.description)
        }
        if (edit.image != "") {
            setImage(edit.image)
            setImageError(true)
        }
        if (edit.start_date != "" && edit.expiry != "") {
            var sp = []
            let startdate = new Date(edit.start_date);
            let enddate = new Date(edit.expiry);
            sp.push(startdate)
            sp.push(enddate)
            setDateRange(sp)
            var myDate = new Date(edit.start_date);
            var myDate1 = new Date(edit.expiry);
            ss1 = new Date(myDate).toLocaleDateString();
            ss2 = new Date(myDate1).toLocaleDateString(); 
            var sst = ss1 + '-' + ss2
            setCombinedDate(myDate)
            setStartDate(sst)
            setExpiryDate(ss2)
        }
        setShow(true)
    }
    const _onChangeImage = async (images) => {
        if (images == null || images == undefined || images == [] || images == '' || images == {}) {
            image_error('Please select image ')
            setImageError(false)
        }
        else {
            if(images.type!="image/jpeg" && images.type!="image/jpg" && images.type!="image/png")
            {
                image_error("Please upload the following file types: jpeg, and png.");
                return false;
            }
            var _size = images.size;
            if(_size>1000000)
            {
                image_error("The size limit for images is 1MB. Reduce the file size and try again.");
                return false;
            }
            image_error('')
            setImageError(true)
            let formData = new FormData();
            formData.append('images', images);
            let image_response = await apiRequest(formData, URL.UPLOAD_IMAGE, 'post')
            if (image_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            }
            setImage(URL.image_url + image_response.data.images)
        }
    }
    const notify = (message) => {
        toast.success(message)
    }
    const errornotify = (message) => {
        toast.error(message)
    }
    const onSubmit = async () => {
        if (name == '' || images == '' || images == null || images == undefined || description == '' || locationId == '' || dateRange[0] == '' || dateRange[0] == null || dateRange[0] == undefined || dateRange[1] == '' || dateRange[1] == null || dateRange[1] == undefined) {
            setName(name)
            const _ValidateFirstName = validateName(name, 'category name');
            if (_ValidateFirstName['status']) {
                name_error('')
                setNameError(true)
            }
            else {
                name_error(_ValidateFirstName['error'])
                setNameError(false)
            }
            setDescription(description)
            if (description !== '') {
                description_error('')
                setDescriptionError(true)
            }
            else if (description === '' || description === undefined || description === null) { 
                description_error('Please enter  description')
                setDescriptionError(false)
            }
            setStartDate(start)
            setExpiryDate(expiry)
            if (start != '' && expiry != '') {
                setDatePicker(false)
                start_error('')
                setStartError(true)
            }
            else {
                setDatePicker(true)
                start_error('please select sheduled time')
                setStartError(false)
            }
            setLocationId(locationId)
            if (locationId != '') {
                location_error('')
                setLocationError(true)
            }
            else {
                location_error('please select location')
                setLocationError(false)
            }
            if (images == null || images == undefined || images == [] || images == '' || images == {}) {
                image_error('Please select image ')
                setImageError(false)
            }
            if (dateRange[0] != "" && dateRange[1] != "" && dateRange[0] != null && dateRange[1] != null && dateRange[0] != null && dateRange[1] != undefined) {
                sheduled_error('')
                setSheduledError(true)
            }
            else if (dateRange[0] == null || dateRange[1] == null || dateRange[0] == null || dateRange[1] == undefined) {
                sheduled_error('please select sheduled time')
                setSheduledError(false)
            }
        }
        else {
            var data_category = {
                "name": name,
                "image": images,
                "start_date": dateRange[0],
                "expiry": dateRange[1],
                "description": description,
                "locationId": locationId,
                "status": "non"
            }
            try {
                if (edits === false) {
                    method = 'post'
                    update =  'Added successfully'
                }
                else if (edits === true) {
                    method = 'patch'
                    data_category.id = ID
                    update = "Updated successfully" 
                }
                data_category.id_deleted = false;
                let staff_response = await apiRequest(data_category, URL.BANNERS, method)
                if (staff_response.error) {
                    errornotify("Failed");
                    return;
                } else {
                    notify(update)
                    setShow(false)
                    setName("");
                    setImage("");
                    setDescription("")
                    setStartDate("")
                    setExpiryDate("")
                    setCombinedDate("")
                    setLocationId("")
                    setTimeout(() => {
                        window.location.reload();
                    }, 200);
                }
            }
            catch (err) {
                errornotify('Failed');
            }
        }
    }
    const getStartDate = (date) => {
        var ss1 = new Date(date);
        var dd = String(ss1.getDate()).padStart(2, '0');
        var mm = String(ss1.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = ss1.getFullYear();
        let starts = mm + '/' + dd + '/' + yyyy;
        let convert_start = starts.toString();
        return convert_start
    }
    const handleClose = () => {
        setShow(false);
        setDatePicker(false)
        sheduled_error('')
        name_error("")
        location_error("")
        description_error("")
        start_error("")
        image_error("")
        setName("");
        setImage("");
        setDescription("")
        setStartDate("")
        setExpiryDate("")
        setCombinedDate("")
        setLocationId("");
    }
    const getbanner= async () => {
        let endpoint = URL.BANNERS + '?filter={"order":"id desc","where":{"id_deleted":'+false+'},"include":["location"]}'
        var get_staff = await apiRequest([], endpoint, 'get')
        if (get_staff == null || get_staff == undefined || get_staff.data === '{}' || Object.entries(get_staff.data).length === 0 || get_staff === null || get_staff === undefined || get_staff.length <= 0 || get_staff.data.length <= 0) {
            show_mesage("No records found")
            setLoading(false);
        }
        else {
            if (localStorage.getItem("role") == "locationmanager") {
                var banner_data = [];
                var assigned_locations = localStorage.getItem("locations").split(',');
                console.log("ok");
                for (var i = 0; i < assigned_locations.length; i++){
                    for (var j = 0; j < get_staff.data.length; j++){
                        if (assigned_locations[i] == get_staff[j]?.location?.id || get_staff[j]?.locationId == 1) {
                            console.log("ok");
                            banner_data.push(get_staff[j]);
                        }
                    }
                }
                setTimeout(() => {
                    setBanners(banner_data);
                    setFilterBanners(banner_data);
                    setLoading(false);
                }, 200);
            } else {
                setBanners(get_staff.data)
                setFilterBanners(get_staff.data);
                setLoading(false);
            }
        }
    }
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
    };
    const pages = [];
    for (let i = 1; i <= Math.ceil(get_data.length / itemsPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = get_data.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
        return (
            <li
            key={number}
            id={number}
            onClick={handleClick}
            className={currentPage == number ? "active" : null}
            >
            {number}
            </li>
        );
        } else {
        return null;
        }
    });
    useEffect(async () => {
        let dynamicCSS = await dynamicStylesheet();
        setHighLightColor(dynamicCSS?.highlightColor);
        var localSettings = await setBannerSetting();
        await setGlobalDateFormat(localSettings?.[0]);
        var get_locations = await apiRequest([], URL.ADDLOCATION+'?filter={"where":{"id_deleted":'+false+'}}', 'get')
        if (get_locations.data === '{}' || Object.entries(get_locations.data).length === 0 || get_locations == null || get_locations == undefined || get_locations.length <= 0 || get_locations.data.length <= 0) {
            setLocationData("")
        }
        else {
            let temp_Array = get_locations.data;
            temp_Array.unshift({ id: 0, name: localStorage.getItem("multi_restaurant")=='true' ? 'Select Restaurant' : 'Select Location' }, { id: 1, name: "All" })
            if (localStorage.getItem("role") == "locationmanager") {
                var locations = [];
                var assigned_locations = localStorage.getItem("locations").split(',');
                for (var i = 0; i < temp_Array.length; i++) {
                    for (var j = 0; j < assigned_locations.length; j++) {
                        if (temp_Array[i].id == assigned_locations[j]) {
                            locations.push({
                                id: temp_Array[i].id,
                                name: temp_Array[i].name
                            });
                        }
                    }
                }
                locations.unshift({ id: 0, name: localStorage.getItem("multi_restaurant") == 'true' ? 'Select Restaurant' : 'Select Location' }, { id: 1, name: "All" })
                setTimeout(() => {
                    setLocationData(locations)
                }, 200);
            } else {
                setLocationData(temp_Array)
            }
        }
    }, []);
    const handleShow = () => {
        labelText("Add Banner")
        setEdit(false)
        setShow(true)
        setDateRange("")
        setLocationId("")
    };
    const renderData = (data) => {
        return (
            data.length>0 ? 
            <BannersList values={{
                data: data,
                get_data: get_data,
                handleCheckbox: handleCheckbox,
                global_date: global_date,
                getStartDate: getStartDate,
                onEdit: onEdit,
                onDelete: onDelete,
                view_show_msg: view_show_msg
            }} /> : <center><b>No Records found</b></center>
        );
      };
    const handleCheckbox = async (item, index) => {
        let temp = [...get_data]
        if (temp[index].enable === false || temp[index].enable === null || temp[index].enable === undefined || temp[index].enable == '') {
            let ss = true
            temp[index].enable = ss
            item.enable = ss
            let add_response = await apiRequest(item, URL.BANNERS, 'patch')
            if (add_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            } else {
                setBanners(temp)
                setFilterBanners(temp);
            }
        }
        else if (temp[index].enable === true || temp[index].enable === 'true') {
            let st = false
            item.enable = st
            temp[index].enable = st
            let add_response = await apiRequest(item, URL.BANNERS, 'patch')
            if (add_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            } else {
                setEnables(true)
                setBanners(temp)
                setFilterBanners(temp)
            }
        }
    }
    const handleCallback = async (childData) => {
        if (childData == 0) {
            endpoints = URL.BANNERS + '?filter={"order":"id desc","where":{"id_deleted":'+false+'},"include":["location"]}'
        }
        else if (childData != 0) {
            endpoints = URL.BANNERS + '?filter={"order":"id desc","where":{"locationId":"' + childData + '","id_deleted":' + false +'},"include":["location"]}'
        }
        var get_staff = await apiRequest([], endpoints, 'get')
        if (get_staff == null || get_staff == undefined || get_staff.length <= 0 || get_staff.data.length <= 0) {
            show_mesage("No records found")
            setBanners("")
            setFilterBanners("");
            setLoading(false);
        }
        else {
            if (localStorage.getItem("role") == "locationmanager") {
                var banner_data = [];
                var assigned_locations = localStorage.getItem("locations").split(',');
                // console.log(get_staff.data);
                for (var i = 0; i < assigned_locations.length; i++) {
                    for (var j = 0; j < get_staff.data.length; j++) {
                        if (assigned_locations[i] == get_staff.data[j]?.location?.id || get_staff.data[j]?.locationId == 1) {
                            console.log("ok");
                            banner_data.push(get_staff.data[j]);
                        }
                    }
                }
                setTimeout(() => {
                    setBanners(banner_data);
                    setFilterBanners(banner_data);
                    setLoading(false);
                }, 200);
            } else {
                setBanners(get_staff.data)
                setFilterBanners(get_staff.data);
                setLoading(false);
            }
        }
    }
    const onDelete = async (id) => {
        if (window.confirm('Are you sure?')) {
            let endpoint = URL.BANNERS
            var delete_locationmanager = await apiRequest({
                id: id,
                id_deleted: true
            }, endpoint, 'patch')
            if (delete_locationmanager == null || delete_locationmanager == undefined || delete_locationmanager.data.length <= 0) {
                errornotify("'Failed'");
            }
            else {
                notify("Deleted successfully")
                getbanner()
            }   
        } else {
            return false;
        }
    }
    const SearchData = (value) => {
        let data = get_data;
        const searchString = value.toLowerCase();
        if(searchString.length>0){
            const filteredResults = data.filter(item => {
                return (
                    item.name.toLowerCase().includes(searchString)
                )
            });
            if(filteredResults){
                setBanners(filteredResults);
                setFilterBanners(filteredResults);
                setcurrentPage(1);
            }else{
                getbanner();
            }
        }else{
            getbanner();
        }
    }
    const handleNextbtn = () => {
        setcurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
          setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
      };
      const handlePrevbtn = () => {
        setcurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
          setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
      };
      let pageIncrementBtn = null;
      if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
      }
      let pageDecrementBtn = null;
      if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
      }
    //   const handleLoadMore = () => {
    //     setitemsPerPage(itemsPerPage + 5);
    // };
    const filterBanners = (filtertype) => {
        var banners = [];
        if (filtertype == "all") {
            window.location.reload();
        } else if (filtertype == "expired") {
            for (var i = 0; i < filterbanners.length; i++) {
                if (filterbanners[i].expiry < new Date().toISOString()) {
                    banners.push(filterbanners[i]);
                }
            }
            setBanners(banners);
        } else if (filtertype == "not expired") {
            for (var i = 0; i < filterbanners.length; i++) {
                if (filterbanners[i].expiry > new Date().toISOString()) {
                    banners.push(filterbanners[i]);
                }
            }
            setBanners(banners);
        }
    }
    return (
        <div>
            <html lang="en">
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                <div class="sticky">
                    <Header parentCallback={(params) => handleCallback(params)} />
                </div>
                <body>
                    <div class="middle_block">
                        <Sidebar />
                        <div class="main_content">
                            <div class="container-fluid">
                                <div class="manage_banners">
                                    <div class="sec_title">
                                        <h2 class="head_sty1">Banners</h2>
                                        {BannersStatusFilter(filterBanners)}
                                        <div  class="search_box_wrap search_customer">
                                            <div style={{"display" : "flex"}}>
                                                <div style={{ "display": "flex" }}>
                                                    <input type="text" onChange={(e) => SearchData(e.target.value)} id="searchbar" id="search" class="form-control" placeholder="Type to search..." />
                                                    <i style={{ "background": highlightColor ? highlightColor : "#fff" }} class="fa fa-search fa-border custom_icon"></i>
                                            </div>
                                            <button class="btn_sty_1" onClick={handleShow} >Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                        {(loading==false) ? renderData(currentItems) : <img className="loading" src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"></img>}
                                    
                                </div>
                                <BannerModal
                                    values={{
                                        show: show,
                                        handleClose: handleClose,
                                        labels: labels,
                                        images: images,
                                        banners_images: banners_images,
                                        _onChangeImage: _onChangeImage,
                                        viewImageError: viewImageError,
                                        view_image_error: view_image_error,
                                        name: name,
                                        _onChangeText: _onChangeText,
                                        viewNameError: viewNameError,
                                        view_name_error: view_name_error,
                                        startDate, startDate,
                                        endDate: endDate,
                                        multi_restaurant : localStorage.getItem("multi_restaurant"), 
                                        setDateRange: setDateRange,
                                        setSheduledError: setSheduledError,
                                        sheduled_error: sheduled_error,
                                        viewSheduledError: viewSheduledError,
                                        view_sheduled_error: view_sheduled_error,
                                        locationId: locationId,
                                        locations: locations,
                                        viewLocationError: viewLocationError,
                                        view_location_error: view_location_error,
                                        description: description,
                                        viewDescriptionError: viewDescriptionError,
                                        view_description_error: view_description_error,
                                        onSubmit: onSubmit
                                    }}
                                />
                                
                            </div>
                        </div>
                    </div>
                </body>
            </html>
            <ul className="pageNumbers">
        <li onClick={handlePrevbtn}
            className={currentPage == pages[0] ? "disallow" : "allow"}>
            {"<"}
        </li>
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}
        <li onClick={handleNextbtn}
            className={currentPage == pages[pages.length - 1] ? "disallow" : "allow"}>
            {">"}
        </li>
      </ul>
    </div>
    )
}
export default Banners;