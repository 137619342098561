import { useState, useEffect } from "react";
import Header from "./header"
import Sidebar from "./menu"
import * as URL from '../../src/services/ServiceConstant';
import apiRequest from "../services/ServiceRequest";
import { toast } from 'react-toastify';
import { fetchUpload } from '../utils/common';
import Select from "react-select";
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const EmailTemplates = (props) => {
    toast.configure()
    const [type, setType] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [emailtemplatetypes, setEmailTemplateTypes] = useState([]);
    const [emailtemplateId, setEmailTemplateId] = useState('');
    const [variable_placeholder, setVariablePlaceHolder] = useState('message');
    const [variables, setVariables] = useState([
        {
            id: '{customer name}',
            value: '{customer name}',
            label: '{customer name}',
            name: '{customer name}'
        },
        {
            id: '{restaurant name}',
            value: '{restaurant name}',
            label: '{restaurant name}',
            name: '{restaurant name}'
        },
        {
            id: '{location}',
            value: '{location}',
            label: '{location}',
            name: '{location}'
        },
        {
            id: '{driver name}',
            value: '{driver name}',
            label: '{driver name}',
            name: '{driver name}'
        },
        {
            id: '{driver number}',
            value: '{driver number}',
            label: '{driver number}',
            name: '{driver number}'
        },
        {
            id: '{customer address}',
            value: '{customer address}',
            label: '{customer address}',
            name: '{customer address}'
        },
        {
            id: '{business address}',
            value: '{business address}',
            label: '{business address}',
            name: '{business address}'
        },
        {
            id: '{business email}',
            value: '{business email}',
            label: '{business email}',
            name: '{business email}'
        },
        {
            id: '{business mobile}',
            value: '{business mobile}',
            label: '{business mobile}',
            name: '{business mobile}'
        },
        {
            id: '{customer first name}',
            value: '{customer first name}',
            label: '{customer first name}',
            name: '{customer first name}'
        },
        {
            id: '{customer last name}',
            value: '{customer last name}',
            label: '{customer last name}',
            name: '{customer last name}'
        },
        {
            id: '{driver first name}',
            value: '{driver first name}',
            label: '{driver first name}',
            name: '{driver first name}'
        },
        {
            id: '{driver last name}',
            value: '{driver last name}',
            label: '{driver last name}',
            name: '{driver last name}'
        },
        {
            id: '{driver email}',
            value: '{driver email}',
            label: '{driver email}',
            name: '{driver email}'
        },
        {
            id: '{business name}',
            value: '{business name}',
            label: '{business name}',
            name: '{business name}'
        },
        {
            id: '{order id}',
            value: '{order id}',
            label: '{order id}',
            name: '{order id}'
        },
        {
            id: '{location manager name}',
            value: '{location manager name}',
            label: '{location manager name}',
            name: '{location manager name}'
        },
        {
            id: '{location name}',
            value: '{location name}',
            label: '{location name}',
            name: '{location name}'
        },
        {
            id: '{product name}',
            value: '{product name}',
            label: '{product name}',
            name: '{product name}'
        },
        {
            id: '{product id}',
            value: '{product id}',
            label: '{product id}',
            name: '{product id}'
        },
        {
            id: '{category name}',
            value: '{category name}',
            label: '{category name}',
            name: '{category name}'
        },
        {
            id: '{product price}',
            value: '{product price}',
            label: '{product price}',
            name: '{product price}'
        },
        {
            id: '{location manager phone}',
            value: '{location manager phone}',
            label: '{location manager phone}',
            name: '{location manager phone}'
        },
        {
            id: '{location manager email}',
            value: '{location manager email}',
            label: '{location manager email}',
            name: '{location manager email}'
        },
        {
            id: '{driver password setup link}',
            value: '{driver password setup link}',
            label: '{driver password setup link}',
            name: '{driver password setup link}'
        },
        {
            id: '{location manager password setup link}',
            value: '{location manager password setup link}',
            label: '{location manager password setup link}',
            name: '{location manager password setup link}'
        },
        {
            id: '{banner name}',
            value: '{banner name}',
            label: '{banner name}',
            name: '{banner name}'
        },
        {
            id: '{banner schedule}',
            value: '{banner schedule}',
            label: '{banner schedule}',
            name: '{banner schedule}'
        },
        {
            id: '{banner locations}',
            value: '{banner locations}',
            label: '{banner locations}',
            name: '{banner locations}'
        },
        {
            id: '{banner end date}',
            value: '{banner end date}',
            label: '{banner end date}',
            name: '{banner end date}'
        },
        {
            id: '{promocode name}',
            value: '{promocode name}',
            label: '{promocode name}',
            name: '{promocode name}'
        },
        {
            id: '{promocode validity}',
            value: '{promocode validity}',
            label: '{promocode validity}',
            name: '{promocode validity}'
        },
        {
            id: '{promocode validity type}',
            value: '{promocode validity type}',
            label: '{promocode validity type}',
            name: '{promocode validity type}'
        },
        {
            id: '{promocode value}',
            value: '{promocode value}',
            label: '{promocode value}',
            name: '{promocode value}'
        },
        {
            id: '{promocode type}',
            value: '{promocode type}',
            label: '{promocode type}',
            name: '{promocode type}'
        },
        {
            id: '{promocode locations}',
            value: '{promocode locations}',
            label: '{promocode locations}',
            name: '{promocode locations}'
        },
        {
            id: '{promocode end date}',
            value: '{promocode end date}',
            label: '{promocode end date}',
            name: '{promocode end date}'
        },
        {
            id: '{driver id}',
            value: '{driver id}',
            label: '{driver id}',
            name: '{driver id}'
        }
    ]);
    const [variablevalue, setVariableValue] = useState([]);
    useEffect(async () => {
        var api_response = await apiRequest([], URL.BASE_URL + '/fetch-email-template-types', 'get');
        setEmailTemplateTypes(api_response.data);
    }, []);
    const notify = (message) => {
        toast.success(message)
    }
    const errornotify = (message) => {
        toast.error(message)
    }
    const handleCallback = async (childData) => {

    }
    const updateTemplate = async () => {
        if (type == "" || subject == "" || message == "") {
            errornotify("invalid data!!");
        } else {
            var api_response = await apiRequest({
                emailtemplateId: emailtemplateId,
                subject: subject,
                message: message
            }, URL.BASE_URL + `/update-email-template`, 'post');
            if (api_response.status == 200) {
                notify("Updated successfully!!");
            }
        }
    }
    const getType = async (id) => {
        var api_response = await apiRequest({
            emailtemplatetypeId: id
        }, URL.BASE_URL + '/fetch-email-template-by-type', 'post');
        if (api_response?.data?.id != '' || api_response?.data?.id != undefined) {
            setSubject(api_response?.data[0].subject);
            setMessage(api_response?.data[0].message);
            setEmailTemplateId(api_response?.data[0].id);
        } else {
            setSubject('');
            setMessage('');
            setEmailTemplateId('');
        }
    }
    const hanleOnSelectVariable = (item) => {
        if (variable_placeholder == "message") {
            setMessage(message + item.label);
        } else if (variable_placeholder == "subject") {
            setSubject(subject + item.label);
        }
    }
    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    fetchUpload(loader, resolve, reject, body);
                });
            }
        }
    }
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        }
    }
    return (
        <div>
            <html lang="en">
                <div class="sticky">
                    <Header parentCallback={handleCallback} />
                </div>
                <body>
                    <div class="middle_block">
                        <Sidebar />
                        <div class="main_content">
                            <div class="container-fluid">
                                <div class="content_block">
                                    <div class="sec_title">
                                        <h2 class="head_sty1">Email Templates</h2>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>Type *</label>
                                            <select onChange={(e) => {
                                                if (e.target.value == "") {
                                                    setSubject('');
                                                    setMessage('');
                                                    setEmailTemplateId('');
                                                    return false;
                                                }
                                                setType(e.target.value);
                                                getType(e.target.value);
                                            }} class="form-control">
                                                <option value="">Select Type</option>
                                                {
                                                    emailtemplatetypes.length > 0
                                                        ? emailtemplatetypes?.map((item, index) => {
                                                            return (
                                                                <option value={item.id} >{item.name}</option>
                                                            )
                                                        })
                                                        : null
                                                }
                                            </select><br></br>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Subject *</label>
                                            <input value={subject} onClick={(e) => setVariablePlaceHolder('subject')} onChange={(e) => setSubject(e.target.value)} class="form-control" type="text"></input><br></br>
                                        </div>
                                        <div class="col-md-12">
                                            <label>Message *</label>
                                            <CKEditor
                                                style={{ "min-height": "1000" }}
                                                editor={ClassicEditor}
                                                data={message}
                                                onFocus={(e) => setVariablePlaceHolder('message')}
                                                onReady={editor => {
                                                }}
                                                config={{
                                                    extraPlugins: [uploadPlugin]
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setMessage(data)
                                                }}
                                            />
                                        </div>
                                        <div class="col-md-6">
                                            <label>Variables *</label>
                                            <Select
                                                isMulti={false}
                                                value={variablevalue}
                                                onChange={(value) => hanleOnSelectVariable(value)}
                                                options={variables}
                                            />
                                        </div>
                                        <br />
                                    </div>
                                    <br />
                                    <div class="btn_group">
                                        <button onClick={() => updateTemplate()} type="button" href="#" class="btn_sty_1">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
        </div>
    )
}
export default EmailTemplates;