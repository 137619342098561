import Moment from "react-moment";
function CustomersList(props) {
    return (
    <>
                {
                    props.values.data.length > 0
                        ? props.values.data?.map((item, index) => {
                            return (
                                <div class="m_staff_box">
                                <div class="m_staff_top">
                                    <div class="img_box">    {(item.profile_image) ? <img src={item.profile_image} alt="" /> : <img src={'./img-placeholder.png'} alt="" />}
                                    </div>
                                    <div class="m_staff_info">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h2 class="r_name"> {(item.firstname) ? item.firstname : item.first_name}</h2>
                                            </div>
                                            <div class="col-md-6">
                                                <h4><span>Phone:</span> {item.mobile}</h4>
                                            </div>
                                            <div class="col-md-6 r_align_right">
                                                <h4><span>Email:</span> {item.email}</h4>
                                            </div>
                                        </div>
                                        <div class="m_staff_bottom">
                                                <h4><span>Last Ordered:</span> {item?.last_ordered != undefined ? <Moment format={props.values.global_date ? props.values.global_date : " DD MMM  YYYY-h:mm A"}>{item?.last_ordered ? item?.last_ordered : "30 Dec 2020 12:30 PM"}</Moment> : ""}
                                                </h4>
                                            {
                                                (item.block_status == "unblocked") ? <div class="btn_group">
                                                    <button type="button" href="#" onClick={() => props.values.getCustomerOrders(item.id)} class="btn_sty_1">View Orders</button>
                                                    <a style={{ "width": "105px" }} href="#" onClick={() => props.values.blockCustomer(item.id, 'blocked')} class="grey_btn">Block</a>
                                                </div> :
                                                    <div class="btn_group">
                                                        <button type="button" href="#" onClick={() => props.values.getCustomerOrders(item.id)} class="btn_sty_1">View Orders</button>
                                                        <a href="#" onClick={() => props.values.blockCustomer(item.id, 'unblocked')} class="grey_btn">Unblock</a>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                            )
                        })
                        : <div align="center" style={{ 'font-size': '16' }} >{props.values.view_show_msg}</div>
                }
            
        </>
    );
}
export default CustomersList;