import axios from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map'
import * as URL from "../services/ServiceConstant";
const API_ENDPOINT = URL.BACKEND_ROOT;
const API_UPLOAD_ENDPOINT = URL.API_URL+'v1/upload';
export const getUser = () => {
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
}
export const getToken = () => {
    return localStorage.getItem('token') || null;
}
export const removeUserSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    return "token removed"
}
export const setUserSession = (token, user) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
}
export const setLocalizationSettings = async() => {
    return await axios.get(URL.BACKEND_ROOT+'api/projectSettings/',{
        headers : {
            'Content-Type' : 'application/json'
        }
    }).then(response => {
        if(response){
            var format = response?.data?.extracted?.[0]?.dateFormat;
            var timeformat = response?.data?.extracted?.[0]?.timeFormat;
            var currencyCode = response?.data?.extracted?.[0]?.currency;
            var currentSymbol = getSymbolFromCurrency(currencyCode);
            var d = '';
            var t = '';
            if(format=="default"){
                d = "ddd MMM D YYYY";
            }else if(format=="shortDate"){
                d = "M/D/YY";
            }else if(format=="paddedShortDate"){
                d = "MM/DD/YYYY";
            }else if(format=="mediumDate"){
                d = "MMM DD, YYYY";
            }else if(format=="longDate"){
                d = "MMMM DD, YYYY";
            }else if(format=="fullDate"){
                d = "dddd, MMMM DD, YYYY";
            }else if(format=="isoDate"){
                d = "YYYY-MM-DD";
            }else if(format=="isoDateTime"){
                d = "YYYY-MM-DDTHH:MM:SS+0530";
            }else if(format=="isoUtcDateTime"){
                d = "YYYY-MM-DDTHH:MM:SSZ";
            }else if(format=="custom"){
                d = "ddd MMM DD YYYY HH:MM:SS";
            }else{
                d = "";
            }
            if(timeformat=="shortTime"){
                t = "h:mm A";
            }else if(timeformat=="mediumTime"){
                t = "h:mm:ss A";
            }
            return [d+" "+t,currentSymbol];
        }else{
            return 0;
        }
    }).catch(err => {
        return 0;
    })
}
export const setPromoCodeSetting = async () => {
    return await axios.get(URL.BACKEND_ROOT+'api/projectSettings/', {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (response) {
            var format = response?.data?.extracted?.[0]?.dateFormat;
            var d = '';
            if (format == "default") {
                d = "ddd MMM D YYYY";
            } else if (format == "shortDate") {
                d = "M/D/YY";
            } else if (format == "paddedShortDate") {
                d = "MM/DD/YYYY";
            } else if (format == "mediumDate") {
                d = "MMM DD, YYYY";
            } else if (format == "longDate") {
                d = "MMMM DD, YYYY";
            } else if (format == "fullDate") {
                d = "dddd, MMMM DD, YYYY";
            } else if (format == "isoDate") {
                d = "YYYY-MM-DD";
            } else if (format == "isoDateTime") {
                d = "YYYY-MM-DDTHH:MM:SS+0530";
            } else if (format == "isoUtcDateTime") {
                d = "YYYY-MM-DDTHH:MM:SSZ";
            } else if (format == "custom") {
                d = "ddd MMM DD YYYY HH:MM:SS";
            } else {
                d = "";
            }
            return [d];
        } else {
            return 0;
        }
    }).catch(err => {
        return 0;
    })
}
export const setBannerSetting = async () => {
    return await axios.get(URL.BACKEND_ROOT+'api/projectSettings/', {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (response) {
            var format = response?.data?.extracted?.[0]?.dateFormat;
            var d = '';
            if (format == "default") {
                d = "ddd MMM D YYYY";
            } else if (format == "shortDate") {
                d = "M/D/YY";
            } else if (format == "paddedShortDate") {
                d = "MM/DD/YYYY";
            } else if (format == "mediumDate") {
                d = "MMM DD, YYYY";
            } else if (format == "longDate") {
                d = "MMMM DD, YYYY";
            } else if (format == "fullDate") {
                d = "dddd, MMMM DD, YYYY";
            } else if (format == "isoDate") {
                d = "YYYY-MM-DD";
            } else if (format == "isoDateTime") {
                d = "YYYY-MM-DDTHH:MM:SS+0530";
            } else if (format == "isoUtcDateTime") {
                d = "YYYY-MM-DDTHH:MM:SSZ";
            } else if (format == "custom") {
                d = "ddd MMM DD YYYY HH:MM:SS";
            } else {
                d = "";
            }
            return [d];
        } else {
            return 0;
        }
    }).catch(err => {
        return 0;
    })
}
export const getSalesGraphObj = (graphData, global_currency) => {
    return {
                animationEnabled: true,
                title: {
                    text: ""
                },
                axisX: {
                    valueFormatString: "MMM",
                    title: "Range Selected",
                },
                axisY: {
                    title: "Amount",
                    prefix: global_currency ? global_currency : "",
                    includeZero: false
                },
                data: [{
                    yValueFormatString: `${global_currency}#,###`,
                    xValueFormatString: "MMMM",
                    type: "spline",
                    dataPoints: graphData
                }]
            };
}
export const dynamicStylesheet = async() => {
    return axios.get(API_ENDPOINT+'api/lba_theme/',{
        headers : {
            'Content-Type' : 'application/json'
        }
    }).then(response => {
        let result = response.data;
        var cssString = '.left_sidebar ul li a:hover span svg { fill: '+result?.hoverBg+';}.left_sidebar ul li a:hover {text-decoration: none;color: '+result?.hoverBg+';}.active {color: '+result?.topHeaderBg+' !important;}.left_sidebar ul li a {padding: 10px 20px;display: flex;align-items: center;color: '+result?.menuFont+';}.btn_sty_1{background:'+result.highlightColor+' !important}.btn_sty_1:hover{background:'+result.hoverBg+' !important}.grey_btn:hover{background:'+result.hoverBg+'}.pageNumbers li.active{background-color: '+result.highlightColor+';border: 1px solid '+result.highlightColor+';}input:checked + .slider {background-color: '+result.highlightColor+';}.r_order_info h3.price {font-size: 18px;color: '+result.highlightColor+';}.btn_sty_2:hover{background:'+result.hoverBg+' !important}';
        return {
            menuBg : result.menuBg,
            menuFont : result.menuFont,
            topHeaderBg : result.topHeaderBg,
            topHeaderFont : result.topHeaderFont,
            hoverBg : result.hoverBg,
            highlightColor : result.highlightColor,
            css : cssString
        };
    }).catch(err => {
        console.log(err);
    });
}
export function fetchUpload(loader,resolve,reject,body){
    loader.file.then((file) => {
        body.append("uploadImg", file);
        fetch(`${API_UPLOAD_ENDPOINT}`,{
            method : "POST",
            body : body
        }).then((res => res.json()))
        .then((res) => 
            resolve({default : `${API_ENDPOINT}${res.uploadImg}`}))
        .catch((err) => {
            reject(err);
        });
    });
}
export function filterLocations(childData, get_staff, get_locations) {
    var locationManagers = [];
    if (childData != 0) {
        for (var l = 0; l < get_staff.data.length; l++) {
            for (var m = 0; m < get_staff.data[l]?.locations_assigned?.length; m++) {
                if (get_staff.data[l]?.locations_assigned[m] == childData) {
                    locationManagers.push(get_staff.data[l]);
                }
            }
        }
        get_staff.data = locationManagers;
    }
    for (var i = 0; i < get_staff.data.length; i++) {
        get_staff.data[i].locs = [];
    }
    for (var i = 0; i < get_staff.data.length; i++) {
        for (var j = 0; j < get_staff.data[i]?.locations_assigned?.length; j++) {
            for (var k = 0; k < get_locations.data.length; k++) {
                if (get_locations.data[k].id == get_staff.data[i].locations_assigned[j]) {
                    get_staff.data[i].locs.push(get_locations.data[k].name);
                }
            }
        }
    }
    return get_staff.data;
}
export function filterDriverLocations(childData, get_staff, get_locations) {
    console.log(childData);
    console.log(get_staff);
    console.log(get_locations);
    var drivers = [];
    if (childData != 0) {
        for (var l = 0; l < get_staff.data.length; l++) {
            for (var m = 0; m < get_staff.data[l]?.locations_assigned?.length; m++) {
                if (get_staff.data[l]?.locations_assigned[m] == childData) {
                    drivers.push(get_staff.data[l]);
                }
            }
        }
        get_staff.data = drivers;
    }
    for (var i = 0; i < get_staff?.data?.length; i++) {
        get_staff.data[i].locs = [];
    }
    for (var i = 0; i < get_staff?.data?.length; i++) {
        for (var j = 0; j < get_staff?.data[i]?.locations_assigned?.length; j++) {
            for (var k = 0; k < get_locations.data.length; k++) {
                if (get_locations.data[k].id == get_staff?.data[i].locations_assigned[j]) {
                    get_staff?.data[i].locs.push(get_locations.data[k].name);
                }
            }
        }
    }
    return get_staff.data;
}
export function LoaderGif() {
    return "https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif";
}

export async function sortLocationManagers(jsonArray) {
    return jsonArray.sort(function (a, b) {
        return a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase());
    });
}

export async function sortLocations(jsonArray) {
    return jsonArray.sort(function (a, b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
}

export async function sortCategories(jsonArray) {
    return jsonArray.sort(function (a, b) {
        return a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase());
    });
}

export async function sortProducts(jsonArray) {
    return jsonArray.sort(function (a, b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
}

export async function sortCustomers(jsonArray) {
    return jsonArray.sort(function (a, b) {
        return a?.firstname?.toLowerCase().localeCompare(b?.firstname?.toLowerCase()) ? a?.firstname?.toLowerCase().localeCompare(b?.firstname?.toLowerCase()) : a?.first_name?.toLowerCase().localeCompare(b?.first_name?.toLowerCase());
    });
}

export const handleLocationChange = (selectedOption, locations, setLocationError, location_error, setLocationId, setLocationValue) => {
    var s_locationValue = [];
    var s_locationId = [];
    if (selectedOption.length <= 0) {
        setLocationError(false)
        location_error('please select Location')
        setLocationId([]);
        setLocationValue([]);
    } else {
        for (var i = 0; i < selectedOption.length; i++) {
            if (selectedOption[i].label == "All") {
                selectedOption = locations;
            }
        }
        if (selectedOption?.[0]?.label == "All") {
            selectedOption = locations;
        }
        selectedOption.forEach(function (post, i) {
            if (post.value != "All") {
                s_locationValue.push({
                    label: post.label,
                    value: post.value
                });
                s_locationId.push(post.value);
            }
        });
        setLocationError(true)
        setLocationId(s_locationId);
        setLocationValue(s_locationValue);
    }
};