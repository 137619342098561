import { useState, useEffect } from "react";
import Header from "./header"
import Sidebar from "./menu"
import * as URL from '../../src/services/ServiceConstant';
import { Modal } from 'react-bootstrap';
import apiRequest from "../services/ServiceRequest";
import "../assets/css/pagination.css";
import Select from "react-select";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TaxCodesList from "./list/taxcodes";
import { handleLocationChange } from "../utils/common.js"
const Taxcode = (props) => {
    toast.configure()
    var method = ''
    var update = ''
    var endpoints = ''
    const [edits, setEdit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [labels, labelText] = useState("");
    const [get_data, setTaxCode] = useState("");
    const [locations, setLocationData] = useState(null);
    const [categories, setCategoryData] = useState("");
    const [show, setShow] = useState(false);
    const [view_show_msg, show_mesage] = useState("");
    const [categoryId, setCategoryId] = useState([]);
    const [ID, setID] = useState("");
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [locationId, setLocationId] = useState([]);
    const [percentage, setPercentage] = useState("");
    var [productbased, setProductBasedCheck] = useState(true);
    var [locationbased, setLocationBasedCheck] = useState(false);
    const [enabless, setEnables] = useState(false);
    const [view_name_error, name_error] = useState("");
    const [view_type_error, type_error] = useState("");
    const [view_location_error, location_error] = useState("");
    const [view_percentage_error, percentage_error] = useState("");
    const [view_category_error, category_error] = useState("");
    const [locationvalue, setLocationValue] = useState([]);
    const [categoryvalue, setCategoryValue] = useState("");
    const [viewNameError, setNameError] = useState(false);
    const [viewTypeError, setTypeError] = useState(false);
    const [viewLocationError, setLocationError] = useState(false);
    const [viewPercentageError, setPercentageError] = useState(false);
    const [viewCategoryError, setCategoryError] = useState(false);
    const handleCheckbox = async (item, index) => {
        let temp = [...get_data]
        if (temp[index].enable === false || temp[index].enable === null || temp[index].enable === undefined || temp[index].enable == '') {
            let ss = true
            temp[index].enable = ss
            item.enable = ss
            let add_response = await apiRequest(item, URL.TAXCODES, 'patch')
            if (add_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            } else {
                setTaxCode(temp)
            }
        }
        else if (temp[index].enable === true || temp[index].enable === 'true') {
            let st = false
            item.enable = st
            temp[index].enable = st
            let add_response = await apiRequest(item, URL.TAXCODES, 'patch')
            if (add_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            } else {
                setEnables(true)
                setTaxCode(temp)
            }
        }
    }
    const notify = (message) => {
        toast.success(message)
    }
    const errornotify = (message) => {
        toast.error(message)
    }
    const gettaxcode = async () => {
        let endpoint =URL.TAXCODES + '?filter={"where":{"id_deleted":'+false+'},"include":["location","categories"]}'
        var get_loc = await apiRequest([], endpoint, 'get')
        if (get_loc.data === '{}' || Object.entries(get_loc.data).length === 0 || get_loc == null || get_loc == undefined || get_loc.length <= 0 || get_loc.data.length <= 0) {
            show_mesage("No records found")
            setLoading(false);
        }
        else {
            if (localStorage.getItem("role") == "locationmanager") {
                var tax_code_data = [];
                var assigned_locations = localStorage.getItem("locations").split(',');
                for (var i = 0; i < get_loc.data.length; i++){
                    for (var j = 0; j < get_loc.data[i].location.length; j++){
                        for (var k = 0; k < assigned_locations.length; k++){
                            if (assigned_locations[k] == get_loc.data[i].location[j].id) {
                                tax_code_data.push(get_loc.data[i]);
                            }
                        }
                    }
                }
                setTimeout(() => {
                    setTaxCode(tax_code_data)
                    setLoading(false);
                }, 200);
            } else {
                setTaxCode(get_loc.data)
                setLoading(false);
            }
        }
    }
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const handleClick = (event) => {
      setcurrentPage(Number(event.target.id));
    };
    const pages = [];
    for (let i = 1; i <= Math.ceil(get_data.length / itemsPerPage); i++) {
      pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = get_data.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map((number) => {
      if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
        return (
          <li
            key={number}
            id={number}
            onClick={handleClick}
            className={currentPage == number ? "active" : null}
          >
            {number}
          </li>
        );
      } else {
        return null;
      }
    });
    useEffect(async () => {
        var get_locations = await apiRequest([], URL.ADDLOCATION+'?filter={"where":{"id_deleted":'+false+'}}', 'get')
        if (get_locations.data === '{}' || Object.entries(get_locations.data).length === 0 || get_locations == null || get_locations == undefined || get_locations.length <= 0 || get_locations.data.length <= 0) {
        }
        else {
            let temp_Array = get_locations.data;
            temp_Array.unshift()
            let location = []
            location.push({
                label: 'All',
                value: 'All'
            });
            temp_Array.forEach(function (post, i) {
                let s = {
                    value: post.id,
                    label: post.name
                }
                location.push(s)
            });
            setLocationData(location)
        }
        var get_products = await apiRequest([], URL.CATEGORY + '?filter={"where":{"id_deleted":' + false + ',"enable":'+true+'}}', 'get')
        if (get_products.data === '{}' || Object.entries(get_products.data).length === 0 || get_products == null || get_products == undefined || get_products.length <= 0 || get_products.data.length <= 0) {
        }
        else {
            let temp_Product = get_products.data;
            let product = []
            temp_Product.forEach(function (post, i) {
                let s = {
                    value: post.id,
                    label: post.name
                }
                product.push(s)
            });
            setCategoryData(product)
        }

    }, []);
    const renderData = (data) => {
        return (
            <TaxCodesList
                values={{
                    data: data,
                    handleCheckbox: handleCheckbox,
                    onEdit: onEdit,
                    onDelete: onDelete,
                    get_data: get_data,
                    view_show_msg : view_show_msg
                }}
            />
        );
    };
    const handleCategoryChange = (selectedOption) => {
        setCategoryValue(selectedOption);
        if (selectedOption.length <= 0) {
            if (type == "product_based") {
                setCategoryError(false)
                category_error('please select category')
            }
            else {
                setCategoryError(true)
                category_error('')
            }
        }
        else if (selectedOption.length > 0) {
            setCategoryError(true)
            category_error('')
            var price = []
            selectedOption.forEach(function (post, i) {
                var p = post.value;
                price.push(p)
            });
            setCategoryId(price)
        }
    };
    
    const handleClose = () => {
        setShow(false);
        setName("")
        setType("")
        setLocationId("")
        setPercentage("")
        name_error("")
        location_error("")
        type_error("")
        percentage_error("")
    }
    const handleShow = () => {
        setShow(true)
        labelText("Add Taxations")
        setEdit(false)
        setCategoryValue('');
        setLocationValue('');
    };
    const _onChangeText = (type, text) => {
        if (text<0) {
            alert("Negative values are not allowed!!");
            setPercentage('');
            return false;
        }
        if (type === 'name') {
            setName(text)
            if (text !== '') {
                setName(text)
                name_error('')
                setNameError(true)
            }
            else if (text === '' || text === undefined || text === null) {
                name_error('Please enter  tax code')
                setNameError(false)
            }
        }
        else if (type === 'type') {
            setType(text)
            if (text !== '') {
                setType(text)
                type_error('')
                setTypeError(true)
                if (type === 'product_based') {
                }
                else if (type === 'invoice_based') {
                    setCategoryId("")
                    setCategoryError(true)
                }
            }
            else {
                type_error('Please select type.')
                setTypeError(false)
            }
        }
        else if (type === 'location') {
            setLocationId(text)
            if (text !== '') {
                setLocationId(text)
                location_error('')
                setLocationError(true)
            }
            else
            location_error('Please select location.')
            setLocationError(false)
        }
        else if (type === 'percentage') {
            setPercentage(text)
            if (text !== '') {
                setPercentage(text)
                percentage_error('')
                setPercentageError(true)
            }
            else
                percentage_error('Please enter percentage.')
                setPercentageError(false)
        }
    }
    const onSubmit = async () => {
        if (name == "" || type == "" || percentage == "" || viewLocationError == false) {
            setName(name)
            if (name !== '') {
                name_error('')
                setNameError(true)
            }
            else if (name === '' || name === undefined || name === null) {
                name_error('Please enter  taxcode')
                setNameError(false)
            }
            setType(type)
            if (type !== '') {
                type_error('')
                setTypeError(true)
            }
            else if (type === '' || type === undefined || type === null) {
                type_error('Please select type')
                setTypeError(false)
            }
            setPercentage(percentage)
            if (percentage !== '') {
                percentage_error('')
                setPercentageError(true)
            }
            else if (percentage === '' || percentage === undefined || percentage === null) {
                percentage_error('Please enter percentage')
                setPercentageError(false)
            }
            setLocationId(locationId)
            if (locationId != null && locationId == undefined && locationId.length > 0) {
                location_error('')
                setLocationError(true)
            }
            else if (locationId === '' || locationId === undefined || locationId === null || locationId.length <= 0) {
                location_error('Please select location')
                setLocationError(false)
            }
            setCategoryId(categoryId)
            if (categoryId != null && categoryId == undefined && categoryId.length > 0) {
                category_error('')
                setCategoryError(true)
            }
            else if (categoryId === '' || categoryId === undefined || categoryId === null || categoryId.length <= 0) {
                category_error('please select Category')
                setCategoryError(false)
            }
        }
        else {
            if (percentage <= 0) {
                alert("percentage should be greater than zero!!");
                return false;
            }
            try {
                if (percentage != undefined || percentage != '') {
                    if (isNaN(percentage) == true || percentage?.includes('e') == true) {
                        alert("Invalid Tax Percentage!!");
                        return false;
                    }
                }
            } catch (err) {
                console.log(err);
            }
            let type_value = ''
            if (type == "product_based") {
                type_value = "Product Based"
            }
            else if (type == "invoice_based") {
                type_value = "Invoice Based"
            }
            var data_category = {
                "name": name,
                "percent": percentage,
                "type": type_value,
            }
            try {
                if (edits === false) {
                    method = 'post'
                    update = 'Added successfully'
                    data_category.enable = true
                }
                else if (edits === true) {
                    method = 'patch'
                    data_category.id = ID
                    update = "Updated successfully"
                }
                data_category.id_deleted = false;
                let staff_response = await apiRequest(data_category, URL.TAXCODES, method)
                handleCallback(0);
                if (staff_response.error) {
                    errornotify("'Failed', response.error.message");
                    return;
                } else {
                    let throughmodel
                    staff_response.data.id.replace(/"/g, "'");
                    if (type == "product_based") {
                        throughmodel = {
                            "taxcodeId": staff_response.data.id,
                            "categoryId": categoryId,
                            "locationId": locationId
                        }
                    }
                    else {
                        throughmodel = {
                            "taxcodeId": staff_response.data.id,
                            "locationId": locationId,
                            "categoryId": []
                        }
                    }
                    let location_response = await apiRequest(throughmodel, URL.TAXCODELOCATION, 'post')
                    if (location_response.error) {
                        errornotify("'Failed', response.error.message");
                        return;
                    } else {
                        notify(update)
                        setShow(false)
                        setName("");
                        setType("")
                        setLocationId("")
                        setPercentage("")
                        setID("")
                        let endpoint = URL.TAXCODES + '?filter={"where":{"id_deleted":'+false+'},"include":["location","categories"]}'
                        var get_category = await apiRequest([], endpoint, 'get')
                        setTaxCode(get_category.data)
                        setLoading(false);
                    }
                }
            }
            catch (err) {
                errornotify('Failed');
            }
        }
    }
    const onEdit = (edit) => {
        labelText("Edit Taxation")
        setEdit(true)
        setShow(true)
        if (edit.id != "") {
            setID(edit.id)
        }
        if (edit.name != "") {
            setName(edit.name)
        }
        if (edit.type != "") {
            if (edit.type == "Product Based") {
                setType("product_based")
                setProductBasedCheck(true)
                setLocationBasedCheck(false)
            }
            else if (edit.type == "Invoice Based") {
                setType("invoice_based")
                setCategoryError(true)
                setProductBasedCheck(false)
                setLocationBasedCheck(true)
            }
        }
        if (edit.percent != "") {
            setPercentage(edit.percent)
        }
        if (edit.location.length > 0) {
            if (edit.location.length > 0) {
                let temp = []
                temp = edit.location
                let sp = []
                let locationId = []
                temp.forEach(function (post, i) {
                    if (post.id_deleted == false) {
                        let s = {
                            value: post.id,
                            label: ""
                        }
                        sp.push(s)
                        locationId.push(post.id)
                    }
                })
                var tempFav = sp;
                var product = locations;
                product.forEach(productItem => {
                    tempFav.forEach(favItem => {
                        if (productItem.value === favItem.value) {
                            favItem.label = productItem.label;
                        }
                    });
                });
                setLocationValue(tempFav)
                setLocationId(locationId)
                setLocationError(true)
            } else { setLocationError(false) }
        }
        if (edit.type == "Product Based") {
            if (edit.categories.length > 0) {
                setCategoryError(true)
                let temp = []
                let categoryId = []
                temp = edit.categories
                let sp = []
                temp.forEach(function (post, i) {
                    let s = {
                        value: post.id,
                        label: ""
                    }
                    sp.push(s)
                    categoryId.push(post.id)
                })
                var tempFav = sp;
                var product = categories;
                product.forEach(productItem => {
                    tempFav.forEach(favItem => {
                        if (productItem.value === favItem.value) {
                            favItem.label = productItem.label;
                        }
                    });
                });
                setCategoryValue(tempFav)
                setCategoryId(categoryId)
            } else { setCategoryError(false) }
        }
    }
    const handleCallback = async (childData) => {
        if (childData == 0) {
            endpoints = URL.TAXCODES + '?filter={"where":{"id_deleted":'+false+'},"include":["location","categories"]}'
            var get_staff = await apiRequest([], endpoints, 'get')
            if (get_staff == null || get_staff == undefined || get_staff.length <= 0 || get_staff.data.length <= 0) {
                show_mesage("No records found")
                setTaxCode("")
                setLoading(false);
            }
            else {
                if (localStorage.getItem("role") == "locationmanager") {
                    var tax_code_data = [];
                    var assigned_locations = localStorage.getItem("locations").split(',');
                    for (var i = 0; i < get_staff.data.length; i++) {
                        for (var j = 0; j < get_staff.data[i].location.length; j++) {
                            for (var k = 0; k < assigned_locations.length; k++) {
                                if (assigned_locations[k] == get_staff.data[i].location[j].id) {
                                    tax_code_data.push(get_staff.data[i]);
                                }
                            }
                        }
                    }
                    const removeDupliactes = (values) => {
                        let concatArray = values.map(eachValue => {
                            return Object.values(eachValue).join('')
                        })
                        let filterValues = values.filter((value, index) => {
                            return concatArray.indexOf(concatArray[index]) === index

                        })
                        return filterValues
                    }
                    tax_code_data = removeDupliactes(tax_code_data);
                    setTimeout(() => {
                        setTaxCode(tax_code_data)
                        setLoading(false);
                    }, 200);
                } else {
                    setTaxCode(get_staff.data)
                    setLoading(false);
                }
            }
        }
        else if (childData != 0) {
            endpoints = URL.TAXCODES + '?filter={"where":{"id_deleted":'+false+'},"include":["location","categories"]}'
            var get_staff = await apiRequest([], endpoints, 'get')
            if (get_staff == null || get_staff == undefined || get_staff.length <= 0 || get_staff.data.length <= 0) {
                show_mesage("No records found")
                setTaxCode("")
                setLoading(false);
            }
            else {
                let props = []
                get_staff.data.forEach(function (post, i) {
                    for (var i = 0; i < post.location.length; i++) {
                        if (post.location[i].id == childData) {
                            props.push(post)
                        }
                    }
                })
                setTaxCode(props)
                setLoading(false);
            }
        }
    }
    const onDelete = async (id) => {
        if (window.confirm('Are you sure?')) {
            let endpoint = URL.TAXCODES
            var delete_locationmanager = await apiRequest({
                id: id,
                id_deleted: true
            }, endpoint, 'patch')
            if (delete_locationmanager == null || delete_locationmanager == undefined || delete_locationmanager.data.length <= 0) {
                errornotify("'Failed'");
            }
            else {
                notify("Deleted successfully")
                gettaxcode()
            }   
        } else {
            return false;
        }
    }
    const handleNextbtn = () => {
        setcurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
          setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
      };
      const handlePrevbtn = () => {
        setcurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
          setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
      };
      let pageIncrementBtn = null;
      if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
      }
      let pageDecrementBtn = null;
      if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
      }
      const handleLoadMore = () => {
        setitemsPerPage(itemsPerPage + 5);
      };
    return (
        <div>
            <html lang="en">
                <div class="sticky">
                    <Header parentCallback={(params) => handleCallback(params)} />
                </div>
                <body>
                    <div class="middle_block">
                        <Sidebar />
                        <div class="main_content">
                            <div class="container-fluid">
                                <div class="manage_staff">
                                    <div class="sec_title">
                                        <h2 class="head_sty1">Taxations</h2>
                                        <a href="#" class="btn_sty_1" data-toggle="modal" data-target="#add_taxationsModal" onClick={handleShow}>Add</a>
                                    </div>
                                    {(loading==false) ? renderData(currentItems) : <img className="loading" src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"></img>}
                                </div>
                            </div>
                        </div>
                        <Modal class="modalform" size="s" class="modal-dialog modal-dialog-centered modal-lg" style={{ 'width': '1000px;', 'padding-top': '110px' }} fullscreen='true' show={show} onHide={handleClose}>
                            <div class="modal-content">
                                <Modal.Header closeButton>
                                    <Modal.Title>{labels}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div class="modal-body">
                                        <section class="staff_profile_sec">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Tax Type</label>
                                                    <form value={type} onChange={e => _onChangeText('type', e.target.value)}>
                                                        <div class="row" >
                                                            <div style={{"display" : "flex"}} class="col-md-6">
                                                                <input style={{"min-height" : "17px !important", "width" : "38px !important"}} type="radio" value="product_based" name="optradio" checked={type == "product_based"} /> <p>Category Based</p>
                                                            </div>
                                                            <div style={{"display" : "flex"}} class="col-md-6">
                                                                <input style={{"min-height" : "17px !important","width" : "38px !important"}} type="radio" value="invoice_based" name="optradio" checked={type == "invoice_based"} /> <p>Invoice Based</p>
                                                            </div>
                                                            {
                                                                (viewTypeError) ?
                                                                    <small></small> : <><small style={{ color: 'red' }}>{view_type_error}</small></>}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            {
                                                (type == 'product_based') ?
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>Select Category</label>
                                                            <div>
                                                                <Select
                                                                    isMulti={true}
                                                                    value={categoryvalue}
                                                                    onChange={(value) => handleCategoryChange(value)}
                                                                    options={categories}
                                                                />
                                                            </div>
                                                            {
                                                                (viewCategoryError) ?
                                                                    <small></small> : <><small style={{ color: 'red' }}>{view_category_error}</small></>}
                                                        </div>
                                                    </div>
                                                    : null}
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Select {localStorage.getItem("multi_restaurant") == 'true' ? 'Restaurant' : 'Location'}</label>
                                                    <div >
                                                        <Select
                                                            className="loc_tax"
                                                            isMulti={true}
                                                            value={locationvalue}
                                                            onChange={(value) => handleLocationChange(value, locations,
                                                                setLocationError,
                                                                location_error,
                                                                setLocationId,
                                                                setLocationValue
                                                            )}
                                                            options={locations}
                                                        />
                                                    </div>
                                                    {
                                                        (viewLocationError) ?
                                                            <small></small> : <><small style={{ color: 'red' }}>{view_location_error}</small></>
                                                    }
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Tax Code</label>
                                                    <input type="text" value={name} onChange={e => _onChangeText('name', e.target.value)} class="form-control" />
                                                    {
                                                        (viewNameError) ?
                                                            <small></small> : <><small style={{ color: 'red' }}>{view_name_error}</small></>}
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Tax Percentage</label>
                                                    <input type="number" value={percentage} onChange={e => _onChangeText('percentage', e.target.value)} class="form-control" />
                                                    {
                                                        (viewPercentageError) ?
                                                            <small></small> : <><small style={{ color: 'red' }}>{view_percentage_error}</small></>}
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer >
                                    <div class="col-md-12">
                                        <div class="form-group mb-0 staff_profile_btn_group">
                                            <button type="submit" class="btn_sty_1" style={{ "width": '110px' }} onClick={() => onSubmit()}>Submit</button>
                                        </div>
                                    </div>
                                </Modal.Footer>
                            </div>
                        </Modal>
                    </div>
                </body>
            </html>
            <ul className="pageNumbers">
        <li onClick={handlePrevbtn}
            className={currentPage == pages[0] ? "disallow" : "allow"}>
            {"<"}
        </li>
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}
        <li onClick={handleNextbtn}
            className={currentPage == pages[pages.length - 1] ? "disallow" : "allow"}>
            {">"}
        </li>
      </ul>
        </div>
    )
}
export default Taxcode;