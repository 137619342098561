import React, { useState, useEffect } from "react";
import Header from "./header"
import Sidebar from "./menu"
import * as Url from '../../src/services/ServiceConstant';
import { Modal, Dropdown } from 'react-bootstrap';
import apiRequest from "../services/ServiceRequest";
import "../assets/css/pagination.css";
import Moment from 'react-moment';
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import category1 from '../assets/img/customer1.jpg';
import '../assets/css/order.css';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from "react-places-autocomplete";
import axios from "axios";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import CreditCardInput from 'react-credit-card-input';
import { setLocalizationSettings, LoaderGif } from '../utils/common.js';
import * as EmailTemplates from '../utils/emailtemplates.js';
import OrdersAvailableDrivers from "./orders_available_drivers";
import OrderFilters from "./orders_filter";
import OrdersEdit from "./orders_edit";
import OrdersList from "./orders_list";
import OrdersETA from "./orders_eta";
import * as ManualOrder from "./orders_manual.js";
import { validateEmail, validateMobileNo } from "../helpers/validation";
const Orders = (props) => {
    var endpoints = ''
    var cus_id = ''
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [serachedname, serachedName] = useState('');
    const [view_show_msg, show_mesage] = useState("");
    const [get_data, setOrders] = useState("");
    const [get_orders_data, setOrdersData] = useState("");
    const [driver_side_status, setDriverSideStatus] = useState('');
    const [orderId, setOrderId] = useState("");
    const [order_type, setOrderType] = useState("");
    const [orderno, setOrderNo] = useState("");
    const [dropoff_image, setDropOffImage] = useState("");
    const [show, setShow] = useState(false);
    const [order_detail, setOrderDetail] = useState(false);
    const [country_code, setCountryCode] = useState("");
    const [eta, setEta] = useState();
    const [status_value, setStatusvalue] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [type, setType] = useState("");
    const [sheduled_date, sheduledDate] = useState("");
    const [ordered_date, orderedDate] = useState("");
    const [discount_total, setDiscountTotal] = useState(0);
    const [statusus, setStatusus] = useState("");
    const [products, setProducts] = useState("");
    const [tipData, setTip] = useState("");
    const [item_total, setItemTotal] = useState("");
    const [order_total, setOrderTotal] = useState("");
    const [tax_total, setTaxTotal] = useState("");
    const [delivery_amount, setDeliveryAmount] = useState('');
    const [address_name, setAddressName] = useState("");
    const [instructions, setInstructions] = useState("");
    const [note, setNote] = useState("");
    const [product_name, setProductName] = useState("");
    const [product_image, setProductImage] = useState("");
    const [product_price, setProductPrice] = useState("");
    const [product_quantity, setProductQuantity] = useState(0);
    const [edit_order, setEdit] = useState(false);
    const [order_detail_id, setOrderDetailId] = useState("");
    const [assign_driver, setAssgin] = useState(false);
    const [drivers_data, setDrivers] = useState("");
    const [driver_id, setDriverId] = useState("");
    const [order_data, setOrderList] = useState([]);
    const [driverInfo, setdriverInfo] = useState([]);
    const [manual_order_show, setManualOrderClose] = useState('');
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [customerDropdown, setCustomerDropdown] = useState([]);
    const [selected_customer, setSelectedCustomer] = useState({ first_name: '', firstname: '', lastname: '', last_name: '', email: '', mobile: '' });
    const [address, setAddress] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [filtered_orders, setFilteredOrders] = useState([]);
    const [LocationDropdown, setLocationDropdown] = useState([]);
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [delivery_type, setDeliverType] = useState("");
    const [productsData, setProductsData] = useState([]);
    const [productsDropdown, setProductsDropdown] = useState([]);
    const [selected_product, setSelectedProduct] = useState({ show: false, checkedAddons: [] });
    const [selected_addons, setSelectedAddOns] = useState([]);
    const [locations, setLocations] = useState([]);
    const [cust_new_address, setCustNewAddress] = useState('');
    const [cartData, setCartData] = useState([]);
    const [amounts, setAmounts] = useState({ item_total: 0, taxes_and_charges: 0, delivery_charges: 0, grand_total: 0, discount_total: 0 });
    const [promocode, setPromoCode] = useState('');
    const [promocode_applied, setPromoCodeApplied] = useState(false);
    const [platform_settings, setPlatformSettings] = useState([]);
    const [allow_deliveries, setAllowDeliveries] = useState('');
    const [allow_pickups, setAllowPickup] = useState('');
    const [card_holder_name, setCardHolderName] = useState('');
    const [card_number, setCardNumber] = useState('');
    const [offer_amount, setOfferAmount] = useState('');
    const [card_expiry, setCardExpiry] = useState('');
    const [card_cv, setCardCV] = useState('');
    const [global_date, setGlobalDateFormat] = useState('');
    const [global_currency, setGlobalCurrency] = useState('');
    const [delivery_charge, setDeliveryCharge] = useState(0);
    const [delivery_charge_type, setDeliveryChargesType] = useState('');
    const [customer_read_only, setCustomerReadOnly] = useState(false);
    const [customer_new_address, setCustomerNewAddress] = useState(false);
    const [pickup_location, setPickupLocation] = useState('');
    const [customerGeo, setCustomerGeo] = useState({ lat: 0, lng: 0 });
    const [product_block_style, setProductBlockStyle] = useState({ "pointer-events": "none" });
    const [payment_block_style, setPaymentBlockStyle] = useState("none");
    const [delivery_radius, setDeliveryRadius] = useState(0);
    const [help_text, setHelpText] = useState('Please fill the customer details on left first and then search products above.');
    const [payment_help_text, setPaymentHelpText] = useState('Please add at least one item to cart and then fill the payment information.');
    var order_list = []
    var range_data = []
    toast.configure()
    const location = useLocation();
    const searchOptions = { types: ['address'] }
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const [customer_profile, setCustomerProfile] = useState('');
    const [inc_dec_buttons, setIncDecButtons] = useState(true);
    const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
    };
    const pages = [];
    for (let i = 1; i <= Math.ceil(filtered_orders?.length / itemsPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filtered_orders?.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                <li
                    key={number}
                    id={number}
                    onClick={handleClick}
                    className={currentPage == number ? "active" : null}
                >
                    {number}
                </li>
            );
        } else {
            return null;
        }
    });
    useEffect(async () => {
        if (pickup_location.value) {
            createProductsDropdown(pickup_location.value);
        }
    }, [pickup_location]);
    const createProductsDropdown = async (location) => {
        var products = await apiRequest([], Url.BASE_URL + '/Products?filter={"where":{"id_deleted":' + false + ',"enable":' + true + '},"include":[{"relation":"pricings"},{"relation":"location"},{"relation":"addOns","scope":{"include":["category"]}}]}', 'get');
        setProductsData(products.data);
        var productsDropdown = [];
        if (location != 0) {
            for (var i = 0; i < products.data.length; i++) {
                for (var j = 0; j < products.data[i].location.length; j++) {
                    if (products.data[i].location[j].id == location) {
                        productsDropdown.push({
                            id: products.data[i].id,
                            value: products.data[i].id,
                            label: products.data[i].name,
                            addOns: products.data[i].addOns,
                            image: products.data[i].image,
                            name: products.data[i].name,
                            price: products.data[i]?.pricings?.[0]?.unit_price,
                            quantity: 1,
                            categoryId: products.data[i].categoryId
                        });
                    }
                }
            }
        }
        setProductsDropdown(productsDropdown);
    }
    useEffect(async () => {
        var localSettings = await setLocalizationSettings();
        await setGlobalDateFormat(localSettings?.[0]);
        await setGlobalCurrency(localSettings?.[1]);
        var settings = await apiRequest([], Url.BASE_URL + '/platformSettings', 'get');
        var jsonsettings = settings.data;
        setPlatformSettings(jsonsettings);
        setDeliveryChargesType(jsonsettings[9].value);
        setDeliveryCharge(jsonsettings[10].value);
        setDeliveryRadius(jsonsettings[11].value);
        for (var i = 0; i < jsonsettings.length; i++) {
            if (jsonsettings[i].id == "60ffdb515e29402fa0bbf370") {
                setAllowPickup(jsonsettings[i].enabled);
            }
            if (jsonsettings[i].id == "60ffdb515e29402fa0bbf376") {
                setAllowDeliveries(jsonsettings[i].enabled);
            }
        }
        onloadfunction();
    }, [global_date]);
    const setRestaurantLocation = async (customerGeo) => {
        if (customerGeo == "new") {
            setCustNewAddress(customerGeo);
        } else {
            var customerAddress = JSON.parse(customerGeo);
            if (customerAddress?.id == undefined) {
                setCustNewAddress("new");
            } else {
                setCustNewAddress(customerAddress?.id);
            }
            setCustomerGeo(customerAddress?.geolocation);
            setLat(customerAddress?.geolocation?.lat);
            setLng(customerAddress?.geolocation?.lng);
            if (selected_customer.email != "") {
                setProductBlockStyle({ "pointer-events": "" });
                setHelpText("Search products and add to the order, so that they will appear here.");
            }
            var pickup = await apiRequest(customerAddress.geolocation, Url.BASE_URL + '/user-nearest-locations', 'post');
            if (delivery_radius < pickup.data[0].distance) {
                alert("There is no closer location to this address.");
                return false;
            }
            setPickupLocation({
                label: pickup.data[0].locationData.name,
                value: pickup.data[0].locationData.id,
                address: pickup.data[0].locationData.address
            });
        }
    }
    const Amounts = async () => {
        var item_total = 0;
        var taxes_and_charges = 0;
        var delivery_charges = 0;
        var grand_total = 0;
        var categories = [];
        var locationId = pickup_location?.value;
        for (var i = 0; i < cartData.length; i++) {
            item_total = item_total + cartData[i].itemTotal;
            categories.push(cartData[i].categoryId);
        }
        if (cartData.length > 0) {
            if (delivery_charge_type == "fixed") {
                delivery_charges = delivery_charge;
            } else {
                delivery_charges = (item_total * delivery_charge) / 100;
            }
        }
        var taxesApiCall = await apiRequest({
            categories: categories,
            locationId: locationId,
            item_total: item_total
        }, Url.BASE_URL + '/get-tax-amount', 'post');
        taxes_and_charges = taxesApiCall?.data?.tax;
        grand_total = parseInt(parseInt(item_total) + parseInt(taxes_and_charges) + parseInt(delivery_charges));
        setAmounts({
            item_total: item_total,
            taxes_and_charges: taxes_and_charges,
            delivery_charges: delivery_type == "delivery" ? delivery_charges : 0,
            grand_total: grand_total,
            discount_total: amounts.discount_total
        });
    }
    const PlaceOrder = async () => {
        if (selected_customer.mobile == '' || selected_customer.email == '') {
            alert("please provide customer details to proceed the order!!");
            return false;
        }
        var emailValidation = validateEmail(selected_customer.email);
        if (emailValidation.status == false) {
            alert("please provide valid email address!!");
            return false;
        }
        var mobileValidation = selected_customer.mobile.length;
        if (mobileValidation != 10) {
            alert("please provide valid mobile number!!");
            return false;
        }
        if (allow_deliveries == true && allow_pickups == true) {
            if (delivery_type == null || delivery_type == '' || delivery_type == undefined) {
                alert("please choose the delivery or pickups options to proceed the order!!");
                return false;
            }
        }
        if (delivery_type == "delivery") {
            if (lat == "" || lng == "" || lat == null || lng == null || lat == undefined || lat == undefined || pickup_location == "") { alert("Address is mandatory!!"); return false; }
        } else {
            if (pickup_location.value == undefined) {
                alert("Address is mandatory!!");
                return false;
            }
        }
        if (cartData.length == 0 || cartData == [] || cartData == undefined || cartData == {}) {
            alert("cart is empty, please add items into the cart to process the order!!");
            return false;
        }
        if (card_holder_name == '' || card_cv == '' || card_expiry == '') {
            alert("please provide payment details to proceed the order!!");
            return false;
        }
        else {
            const apiPostHeaders = {
                "Content-Type": "application/json"
            };
            const email = "surya@yopmail.com";
            var customerId = selected_customer.id ? selected_customer.id : undefined;
            var customer_name = selected_customer.firstname ? selected_customer.firstname : selected_customer.first_name;
            var customer_lastname = selected_customer.last_name ? selected_customer.last_name : '';
            var customer_mobile = selected_customer.mobile ? selected_customer.mobile : '';
            var customer_email = selected_customer.email ? selected_customer.email : '';
            var item_total = 0;
            var order_total = 0;
            var tax_total = 0;
            var orderDetail = [];
            for (var i = 0; i < cartData.length; i++) {
                item_total = item_total + (cartData[i].price * cartData[i].quantity);
                orderDetail.push({
                    product_price: cartData[i].price,
                    quantity: cartData[i].quantity,
                    productId: cartData[i].id
                });
            }
            order_total = parseInt(parseInt(item_total) + parseInt(amounts.taxes_and_charges) + parseInt(amounts.delivery_charges));
            // alert(order_total);
            // return false;
            var orderData = {
                customerId: customerId,
                customer_name: customer_name,
                customer_lastname: customer_lastname,
                customer_mobile: customer_mobile,
                customer_email: customer_email,
                item_total: item_total,
                order_total: order_total,
                tax_total: amounts.taxes_and_charges,
                orderDetail: orderDetail,
                customer_new_address: customer_new_address,
                cust_new_address: cust_new_address,
                address: address,
                dropoff_location: {
                    lat: lat,
                    lng: lng
                },
                pickup_location: {
                    lat: pickup_location?.address?.geolocation?.lat,
                    lng: pickup_location?.address?.geolocation?.lng
                },
                locationId: pickup_location?.value,
                driver_side_status: 'waiting',
                type_of_order: delivery_type,
                delivery_charge: amounts.delivery_charges ? amounts.delivery_charges : null
            };
            setLoading(true);
            await axios({
                method: "post",
                url: Url.BASE_URL + '/banking/addCard',
                timeout: 1000 * 5,
                headers: apiPostHeaders,
                data: {
                    email: email,
                    card: {
                        name: card_holder_name,
                        number: card_number.split(/\s/).join(''),
                        exp_month: card_expiry.split('/')[0].split(/\s/).join(''),
                        exp_year: card_expiry.split('/')[1].split(/\s/).join(''),
                        cvc: card_cv
                    }
                }
            })
                .then(async (cardResponse) => {
                    const addCardResponse = cardResponse.data.response;
                    await axios({
                        method: 'post',
                        url: Url.BASE_URL + '/banking/charge/card',
                        timeout: 1000 * 5,
                        headers: apiPostHeaders,
                        data: {
                            options: {
                                email: email
                            },
                            amount: order_total,
                            card: addCardResponse?.card?.id,
                            currency: "inr"
                        }
                    }).then(async (response) => {
                        setLoading(false);
                        orderData.card = addCardResponse?.card;
                        orderData.charge_id = response.data.response.charge_id;
                        if (delivery_type == "pickup") {
                            orderData.delivery_charge = 0;
                        }
                        var manual_order = await apiRequest(orderData, Url.BASE_URL + '/place-manual-order', 'post');
                        if (manual_order?.data?.status == 400) {
                            alert("Customer email or mobile already exists!!");
                            return false;
                        } else {
                            if (localStorage.getItem("role") == "businessowner") {
                                await apiRequest({
                                    title: 'New Order Received',
                                    message: 'New Order Received',
                                    type: 'web',
                                    status: 'open',
                                    datetime: new Date().toISOString()
                                }, Url.BASE_URL + '/Notifications', 'post');
                            }
                            notify("Added successfully");
                            getOrders()
                            setManualOrderClose(false);
                            window.location.reload();
                        }
                    }).catch(err => {
                        console.log(err);
                        errornotify("payment failed!!")
                        setLoading(false);
                    });
                })
                .catch(error => {
                    console.log(error);
                    errornotify("payment failed!!")
                    setLoading(false);
                });
        }
    }
    const handleSelect = async value => {
        if (selected_customer.email.trim() == '') {
            alert("please fill customer details first!!");
            setAddress('');
            return false;
        }
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        var address_components = value.split(',');
        var country = address_components[address_components.length - 1];
        var state = address_components[address_components.length - 2];
        var city = address_components[address_components.length - 3];
        setCountry(country);
        setState(state);
        setCity(city);
        setAddress(value);
        setCoordinates(latLng);
        setLat(coordinates.lat);
        setLng(coordinates.lng);
        const [place] = await geocodeByPlaceId(results[0].place_id);
        const { long_name: postcode = '' } =
            place.address_components.find(c => c.types.includes('postal_code')) || {};
        setZipcode(postcode);
        await setRestaurantLocation(JSON.stringify({
            geolocation: latLng
        }));
    };
    async function onloadfunction() {
        if (location.state != null && location.state != undefined && location.state != '') {
            if (location.state.id != null && location.state.id != undefined && location.state.id != '') {
                cus_id = location.state.id
            }
        }
        var filter_type = window.location.search.substring(1).split('=')[0];
        var query = window.location.search.substring(1).split('=')[1];
        cus_id = query;
        getOrders(filter_type, cus_id)
        var customers = await apiRequest([], Url.BASE_URL + '/Customers?filter={"include":["addresses"]}', 'get');
        setCustomers(customers.data);
        var customerDropdown = [];
        for (var i = 0; i < customers.data.length; i++) {
            var customerFirstName = customers.data[i].firstname ? customers.data[i].firstname : customers.data[i].first_name;
            var customerLastName = customers.data[i].lastname ? customers.data[i].lastname : customers.data[i].last_name;
            var customerMobile = customers.data[i].mobile;
            var displayName = customerFirstName + " " + customerLastName + " (" + customerMobile + ")";
            if (customers?.data[i]?.is_account_deleted == false) {
                customerDropdown.push({
                    id: customers.data[i].id,
                    name: (customers.data[i].firstname) ? customers.data[i].firstname + " (" + customers.data[i]?.mobile + ")" : customers.data[i].first_name + " (" + customers.data[i]?.mobile + ")",
                    value: customers.data[i].id,
                    label: displayName,
                    first_name: (customers.data[i].firstname) ? customers.data[i].firstname : customers.data[i].first_name,
                    last_name: (customers.data[i].lastname) ? customers.data[i].lastname : customers.data[i].last_name,
                    email: customers.data[i].email,
                    mobile: customers.data[i]?.mobile,
                    addresses: customers.data[i].addresses
                });
            }
        }
        setCustomerDropdown(customerDropdown);
        createProductsDropdown(pickup_location.value);
        var locations = await apiRequest([], Url.BASE_URL + '/Locations?filter={"where":{"id_deleted":' + false + '},"include":["address"]}', 'get');
        setLocations(locations.data);
        var LocationDropdown = [];
        if (localStorage.getItem("role") == "businessowner") {
            for (var i = 0; i < locations.data.length; i++) {
                LocationDropdown.push({
                    value: locations.data[i].id,
                    label: locations.data[i].name,
                    address: locations.data[i].address
                });
            }
        } else {
            var temp_locations = localStorage.getItem("locations").split(',');
            for (var i = 0; i < locations.data.length; i++) {
                if (temp_locations[i] == locations.data[i].id) {
                    LocationDropdown.push({
                        value: locations.data[i].id,
                        label: locations.data[i].name,
                        address: locations.data[i].address
                    });
                }
            }
        }
        setLocationDropdown(LocationDropdown);
    }
    const addItemToCart = (item) => {
        var choose_addons = 0;
        var mandatory_addons = 0;
        for (var i = 0; i < selected_addons.length; i++) {
            if (selected_addons[i].is_mandatory == true) {
                mandatory_addons = mandatory_addons + 1;
                for (var j = 0; j < selected_product.addOns.length; j++) {
                    if (selected_product.addOns[j].category.id == selected_addons[i].id && selected_product.addOns[j].checked == true) {
                        choose_addons = choose_addons + 1;
                    }
                }
            }
        }
        if (choose_addons < mandatory_addons) { alert("choose at least one addons whose category is mandatory!!"); return false; }
        item.itemTotal = item.price * item.quantity;
        for (var i = 0; i < cartData.length; i++) {
            if (cartData[i].id == item.id) {
                return false;
            }
        }
        cartData.push(item);
        setCartData(cartData);
        setPaymentHelpText("");
        setPaymentBlockStyle("block");
        setSelectedProduct(prevState => ({
            ...prevState,
            show: false
        }));
        Amounts();
    }
    const handleCallback = async (childData) => {
        if (window.location.search.substring(1).split('=')[0] == "customer") {
            return;
        }
        if (childData != 0) {
            var locationName = await apiRequest([], Url.BASE_URL + '/Locations?filter={"where":{"id":"' + childData + '"}}', 'get')
            for (var i = 0; i < LocationDropdown.length; i++) {
                if (LocationDropdown[i].value == childData) {
                    setPickupLocation({
                        label: locationName.data?.[0].name,
                        value: childData,
                        address: LocationDropdown[i].address
                    });
                }
            }
            createProductsDropdown(childData);
        }
        if (childData == 0 || childData.length <= 0) {
            endpoints = Url.UPDATEORDERS + '?filter={"order":"ordered_date DESC","where":{"checkout":' + true + '},"include":[{"relation":"orderDetails","scope":{"include":[{"relation":"product","scope":{"include":"pricings"}},{"relation":"addOns"}]}},{"relation":"location","scope":{"include":[{"relation":"address"}]}},{"relation":"customer"},{"relation":"address"},{"relation":"drivers"},{"relation":"status"},{"relation":"tip"}]}'
        }
        else if (childData != 0 && childData.length != 0) {
            endpoints = Url.UPDATEORDERS + '?filter={"order":"ordered_date DESC","where":{"locationId":"' + childData + '","checkout":' + true + '},"include":[{"relation":"orderDetails","scope":{"include":[{"relation":"product","scope":{"include":"pricings"}},{"relation":"addOns"}]}},{"relation":"location","scope":{"include":[{"relation":"address"}]}},{"relation":"customer"},{"relation":"address"},{"relation":"drivers"},{"relation":"status"},{"relation":"tip"}]}'
        }
        var get_staff = await apiRequest([], endpoints, 'get')
        console.log(get_staff);
        if (get_staff == null || get_staff == undefined || get_staff.length <= 0 || get_staff.data.length <= 0) {
            show_mesage("No records found")
            setOrders("")
            setFilteredOrders("");
        }
        else {
            setOrders(get_staff.data);
            if (localStorage.getItem("role") == "businessowner") {
                setFilteredOrders(get_staff.data);
            }
            else {
                var locationsassigned = localStorage.getItem("locations").split(',');
                var locationManagerOrders = [];
                for (var i = 0; i < get_staff.data.length; i++) {
                    for (var j = 0; j < locationsassigned.length; j++) {
                        if (get_staff.data[i].locationId == locationsassigned[j]) {
                            locationManagerOrders.push(get_staff.data[i]);
                        }
                    }
                }
                setFilteredOrders(locationManagerOrders);
                setOrders(locationManagerOrders);
            }
        }
    }
    const handleCheckbox = (item, index) => {
        order_list = order_data
        let temp = [...get_data]
        if (temp[index].enable === false || temp[index].enable === null || temp[index].enable === undefined || temp[index].enable == '') {
            let ss = true
            temp[index].enable = ss
            item.enable = ss
            order_list.push(temp[index].id)
            let order = order_list
            let array = []
            for (var i = 0; i < order_list.length; i++) {
                array.push(order_list[i])
            }
        }
        else if (temp[index].enable === true || temp[index].enable === 'true') {
            let st = false
            item.enable = st
            temp[index].enable = st
            for (var i = 0; i < order_list.length; i++) {
                if (order_list[i].id === temp[index].id) {
                    order_list.splice(i, 1);
                    i--;
                }
            }
        }
        setOrderList(order_list)
    }
    const renderData = (data) => {
        return (
            <OrdersList values={
                {
                    data: data,
                    filtered_orders: data,
                    category1: category1,
                    view_show_msg: view_show_msg,
                    handleShow: handleShow,
                    global_currency: global_currency,
                    handleCheckbox: handleCheckbox,
                    _onChangeDropdown: _onChangeDropdown,
                    handleAssignDriverShow: handleAssignDriverShow,
                    CustomToggle: CustomToggle,
                    status_value: status_value,
                    global_date: global_date
                }
            } />
        );
    };
    const handleDriverCheckbox = async (data) => {
        let temp = [...drivers_data]
        temp.forEach(element => {
            if (element.id == data.id) { element.checked = true } else { element.checked = false }
        }
        );
        setDrivers(temp)
        setDriverId(data.id)
    }
    const getDrivers = async (driver_endpoint) => {
        var get_drivers = await apiRequest([], driver_endpoint, 'get')
        if (get_drivers == null || get_drivers == undefined || get_drivers.data === '{}' || Object.entries(get_drivers.data).length === 0 || get_drivers === null || get_drivers === undefined || get_drivers.length <= 0 || get_drivers.data.length <= 0) {
            show_mesage("No records found")
        }
        else {
            let temp_Product = get_drivers.data;
            setDrivers(temp_Product)
        }
    }
    const getOrders = async (filter_type, id) => {
        if (filter_type == "customer") {
            var get_products = await apiRequest({
                "customerId": id
            }, Url.ORDERDETAILS, 'post')
        } else if (filter_type == "location") {
            var get_products = await apiRequest({
                "locationId": id
            }, Url.BASE_URL + '/location-orders', 'post')
        }
        if (get_products == null || get_products == undefined || get_products.data === '{}' || Object.entries(get_products.data).length === 0 || get_products === null || get_products === undefined || get_products.length <= 0 || get_products.data.length <= 0) {
            show_mesage("No records found")
        }
        else {
            let temp_Product = '';
            if (filter_type == "customer") {
                temp_Product = get_products.data.model;
            } else if (filter_type == "location") {
                temp_Product = get_products.data;
            }
            if (localStorage.getItem("role") == "locationmanager") {
                var locations_assigned = localStorage.getItem("locations").split(',');
                temp_Product = temp_Product.filter(function (item) {
                    for (var i = 0; i < locations_assigned.length; i++) {
                        if (locations_assigned[i] == item.locationId) {
                            return item;
                        }
                    }
                })
            }
            setOrders(temp_Product)
            setOrdersData(temp_Product)
            setFilteredOrders(temp_Product);
        }
    }
    const handleShow = (item, type) => {
        if (type === "order") {
            console.log(item.drivers);
            setdriverInfo(item.drivers);
            setOrderDetail(true)
            setDropOffImage(item.dropoff_image);
            setTip(item?.tip_amount ? item?.tip_amount : 0);
            setShow(false)
            setOrderId(item.id)
            setItemTotal(item.item_total.toFixed(2))
            setOrderType(item?.type_of_order);
            setOrderTotal(item?.order_total.toFixed(2))
            setDriverSideStatus(item.driver_side_status);
            setTaxTotal(item?.tax_total?.toFixed(2))
            setDeliveryAmount(parseFloat(item?.delivery_charge)?.toFixed(2));
            setOfferAmount(item?.offer_amount?.toFixed(2));
            setName(item?.customer?.first_name == undefined ? item?.customer?.firstname : item?.customer?.first_name);
            setCustomerProfile((item?.customer?.profile_image == undefined || item?.customer?.profile_image == '') ? './img-placeholder.png' : item?.customer?.profile_image);
            if (item.customer != null && item.customer != undefined) {
                // if (item.customer.username != null && item.customer.username != undefined) {
                //     setName(item?.customer?.first_name == undefined ? item?.customer?.firstname : item?.customer?.first_name);
                // }
                setPhone(item.customer.mobile)
                setEmail(item.customer.email)
                setAddressName(item.customer.address)
            }
            if (item.type_of_order != null && item.type_of_order != undefined) {
                setType(item.type_of_order)
            }
            setOrderNo(item?.orderNo)
            sheduledDate(item?.selected_date)
            orderedDate(item?.ordered_date)
            setInstructions(item?.instructions)
            setNote(item.note)
            if (item.status != null && item.status != undefined) {
                setStatusus(item.status.order_status[0])
            }
            if (item.orderDetails != null && item.orderDetails != undefined) {
                setProducts(item.orderDetails)

            }
            if (item.address != null && item.address != undefined) {
                setAddressName(item.address.text)
            }
        }
        else if (type === "eta") {
            setOrderDetail("")
            setShow(true)
            setOrderId(item.id)
            setEta(item.eta)
        }
    };
    const showDropOffImage = (image) => {
        window.open(image, "_blank");
    }
    const handleEditOrderShow = async (item) => {
        var orderId = item.orderId;
        var order = await apiRequest({}, Url.BASE_URL + '/Orders/' + orderId, 'get');
        var driver_side_status = order.data.driver_side_status;
        if (driver_side_status == "picked" || driver_side_status == "delivered" || driver_side_status == "accepted" || driver_side_status == "rejected") {
            alert("This order is already picked up. You cannot make any changes to this order anymore.");
            setEdit(false);
            return false;
        } else {
            setEdit(true)
            setOrderDetailId(item.id)
            if (item.product != null && item.product != undefined) {
                setProductImage(item?.product?.image)
                setProductName(item.product.name)
                setProductQuantity(item.quantity)
                setProductPrice(item.product.pricings[0].unit_price)
            }
        }
    }
    const handleAssignDriverShow = () => {
        let drivers_endpoint = Url.DRIVERS + '?filter={"where":{"is_available":"green","id_deleted":' + false + ',"is_account_deleted":' + false + '}}';
        getDrivers(drivers_endpoint)
        var checkboxes = document.querySelectorAll('input[type="checkbox"]:checked').length;
        if (checkboxes == 0) { alert("please select orders that you want to assign to driver!!"); return false; }
        let temp = [...drivers_data]
        temp.forEach(element => {
            element.checked = false
        }
        );
        setAssgin(true)
    }
    const handleAssignDriverClose = () => {
        setAssgin(false)
    }
    const handleEditOrderClose = () => {
        setEdit(false)
    }
    const incrementQuantity = async (orderId, productprice, orderDetailId) => {
        if (product_quantity > 0) {
            let quantity = product_quantity + 1
            if (quantity > 0) {
                setIncDecButtons(false);
                var order = await apiRequest({}, Url.BASE_URL + '/Orders/' + orderId, 'get');
                var orderTotal = order.data.order_total;
                var itemTotal = order.data.item_total;
                const apiPostHeaders = {
                    "Content-Type": "application/json"
                };
                await axios({
                    method: 'post',
                    url: Url.BASE_URL + '/banking/charge/card',
                    timeout: 1000 * 5,
                    headers: apiPostHeaders,
                    data: {
                        options: {
                            email: email
                        },
                        amount: orderTotal + productprice,
                        card: order?.card?.id,
                        currency: "inr"
                    }
                }).then(async (response) => {
                    if (response?.data?.response?.charge_id != undefined) {
                        var charge_id = response.data.response.charge_id;
                        await apiRequest({
                            id: orderId,
                            order_total: orderTotal + productprice,
                            item_total: itemTotal + productprice,
                            charge_id: charge_id
                        }, Url.BASE_URL + '/Orders', 'patch');
                        await apiRequest({
                            id: orderDetailId,
                            quantity: quantity
                        }, Url.BASE_URL + '/OrderDetails', 'patch');
                        setIncDecButtons(true);
                        LoadOrders();
                        setProductQuantity(quantity)
                    } else {
                        setIncDecButtons(true);
                        alert("payment failed, please try again!!")
                        setEdit(false);
                    }
                }).catch(err => {
                    setIncDecButtons(true);
                    alert("payment failed, please try again!!")
                });
            } else {
                setIncDecButtons(true);
                alert("Product quantity cannot be zero!!");
                return false;
            }
        }
    }
    const LoadOrders = async () => {
        handleCallback(0);
        var orderApiCall = await apiRequest({}, Url.UPDATEORDERS + '?filter={"order":"ordered_date DESC","where":{"checkout":' + true + ',"id":"' + orderId + '"},"include":[{"relation":"orderDetails","scope":{"include":[{"relation":"product","scope":{"include":"pricings"}},{"relation":"addOns"}]}},{"relation":"location","scope":{"include":[{"relation":"address"}]}},{"relation":"customer"},{"relation":"address"},{"relation":"drivers"},{"relation":"status"},{"relation":"tip"}]}', 'get');
        setProducts(orderApiCall.data[0].orderDetails);
        setOrderTotal(orderApiCall.data[0].order_total);
        setItemTotal(orderApiCall.data[0].item_total);
        Amounts();
    }
    const updateOrderQuantity = async () => {
        let s = {
            id: order_detail_id,
            quantity: product_quantity
        }
        var update = await apiRequest(s, Url.UPDATE_ORDER_DETAILS, 'patch')
        if (update.error) {
            errornotify("'Failed', response.error.message");
            return;
        } else {
            notify("Updated successfully")
            setEdit(false)
        }
    }
    const decrementQuantity = async (orderId, productprice, orderDetailId) => {
        if (product_quantity > 0) {
            let quantity = product_quantity - 1
            if (quantity > 0) {
                setIncDecButtons(false);
                var order = await apiRequest({}, Url.BASE_URL + '/Orders/' + orderId, 'get');
                var orderTotal = order.data.order_total;
                var itemTotal = order.data.item_total;
                const apiPostHeaders = {
                    "Content-Type": "application/json"
                };
                await axios({
                    method: 'post',
                    url: Url.BASE_URL + '/banking/charge/card',
                    timeout: 1000 * 5,
                    headers: apiPostHeaders,
                    data: {
                        options: {
                            email: email
                        },
                        amount: orderTotal - productprice,
                        card: order?.card?.id,
                        currency: "inr"
                    }
                }).then(async (response) => {
                    if (response?.data?.response?.charge_id != undefined) {
                        var charge_id = response.data.response.charge_id;
                        await apiRequest({
                            id: orderId,
                            order_total: orderTotal - productprice,
                            item_total: itemTotal - productprice,
                            charge_id: charge_id
                        }, Url.BASE_URL + '/Orders', 'patch');
                        await apiRequest({
                            id: orderDetailId,
                            quantity: quantity
                        }, Url.BASE_URL + '/OrderDetails', 'patch');
                        setIncDecButtons(true);
                        LoadOrders();
                        setProductQuantity(quantity)
                    } else {
                        setIncDecButtons(true);
                        alert("payment failed, please try again!!")
                        setEdit(false);
                    }
                    LoadOrders();
                }).catch(err => {
                    setIncDecButtons(true);
                    console.log(err);
                    alert("payment failed, please try again!!")
                });
            } else {
                setIncDecButtons(true);
                alert("Product quantity cannot be zero!!");
                return false;
            }
        }
    }
    const handleClose = async () => {
        setShow(false)
        let update_eta = {
            "id": orderId,
            "eta": eta
        }
        let update_order_response = await apiRequest(update_eta, Url.UPDATEORDERS, 'patch')
        if (update_order_response.error) {
            errornotify("'Failed', response.error.message");
            return;
        } else {
            notify("Updated successfully");
            setEta("")
            getOrders()
        }
    };
    const assignOrders = async () => {
        let s = {
            "id": order_data,
            "driverId": driver_id
        }
        let notification = {
            message: "A new order has been assigned to you by the restaurant",
            driverId: driver_id,
            notification_date: new Date().toISOString(),
            role: "driver",
            title: "order",
            type: "app",
            datetime: new Date().toISOString()
        };
        var update = await apiRequest(s, Url.UPDATE_ORDER, 'patch');
        var updateAssign = await apiRequest({
            id: order_data,
            assign: "manual",
            driver_side_status: "accepted"
        }, Url.BASE_URL + '/assign-orders', 'post');
        if (updateAssign.data.success == 0) {
            alert("Select order is already delivered by the driver!!");
            return false;
        }
        if (localStorage.getItem("role") == "businessowner") {
            var res = await apiRequest(notification, Url.BASE_URL + '/Notifications', 'post');
        }
        if (update.error) {
            errornotify("'Failed', response.error.message");
            return;
        } else {
            notify("Updated successfully")
            setAssgin(false);
            window.location.reload();
        }
    }
    const handleOrderDetailClose = async () => {
        setOrderDetail(false)
    }
    const filterDateRange = async (daterange) => {
        let mindate = new Date(daterange[0]);
        let maxdate = new Date(daterange[1]);
        mindate.setHours(0, 0, 0, 0)
        maxdate.setHours(0, 0, 0, 0);
        let ORDERS = [...get_data]
        ORDERS.forEach(function (p, i) {
            if (p.ordered_date != null && p.ordered_date != undefined) {
                let d = new Date(p.ordered_date);
                d.setHours(0, 0, 0, 0);
                if (d >= mindate) {
                    if (maxdate >= d) {
                        range_data.push(p)
                    }
                }
            }
        })
        setFilteredOrders(range_data);
    }
    const validatePromoCode = async (promocodeValue) => {
        var promocodevalid = false;
        var typevalid = false;
        var locationvalid = false;
        var finalAmount = 0;
        var totalAmounts = amounts.item_total + amounts.taxes_and_charges + amounts.grand_total;
        if (promocode_applied == false) {
            if (amounts.grand_total == 0 || amounts.item_total == 0) {
                alert("add some items to the cart to apply the promocode!!");
                return false;
            }
            var promocodeApi = await apiRequest({
                promocode: promocodeValue
            }, Url.BASE_URL + '/validate-promocode', 'post');
            var response = promocodeApi.data;
            if (Object.keys(response).length == 0) {
                alert("invalid promocode");
            } else {
                if (response.validaty == "date_range") {
                    var today = new Date().toISOString();
                    if (today > response.start_time && today < response.end_time) {
                        promocodevalid = true;
                    }
                } else if (response.validaty == "specific_date") {
                    var today = new Date().toISOString().split('T')?.[0];
                    if (today == response.start_time || today == response.end_time) {
                        promocodevalid = true;
                    }
                } else if (response.validaty == "lifetime") {
                    promocodevalid = true;
                }
                else if (response.validaty == "maximum_validity_count") {
                    promocodevalid = true;
                }
                if (promocodevalid == true) {
                    if (response.type == "Specific Products") {
                        for (var i = 0; i < cartData.length; i++) {
                            if (cartData[i].id == response.product.id) {
                                typevalid = true;
                            }
                        }
                    } else if (response.type == "Minimum Order") {
                        if (totalAmounts >= response.min_order_value) {
                            typevalid = true;
                        } else {
                            alert("minimum value required to apply this coupon");
                            return false;
                        }
                    } else if (response.validaty == "Direct Discount") {
                        typevalid = true;
                    }
                } else {
                    alert("promocode is invalid!!");
                    return false;
                }
                for (var i = 0; i < locations.length; i++) {
                    for (var j = 0; j < response.location.length; j++) {
                        if (response.location[j].id == locations[i].id) {
                            locationvalid = true;
                        }
                    }
                }
                if (promocodevalid == true && typevalid == true && locationvalid == true) {
                    finalAmount = amounts.grand_total - response.amount;
                    setDiscountTotal(response.amount);
                    setPromoCodeApplied(true);
                    await apiRequest({
                        redeem_count: response.redeem_count + 1,
                        id: response.id
                    }, Url.BASE_URL + '/PromoCodes', 'patch');
                    setAmounts({
                        item_total: amounts.item_total,
                        taxes_and_charges: amounts.taxes_and_charges,
                        delivery_charges: delivery_type == "delivery" ? amounts.delivery_charges : 0,
                        grand_total: finalAmount,
                        discount_total: amounts.discount_total
                    });
                    alert("promocode applied successfully");
                } else {
                    alert("promocode is not applicable!!");
                }
            }
        } else if (promocode == promocodeValue && promocode_applied == true) {
            alert("promocode was already applied to this order!!");
        } else {
            alert("failed to apply promo code");
        }
    }
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <span className="threedots" />
        </a>
    ));
    const filterByNameOrId = async (e) => {
        let orders = get_data;
        const searchString = e.target.value.toLowerCase();
        serachedName(searchString);
        if (searchString.length > 0) {
            const filterOrders = orders.filter(item => {
                if (item?.customer?.firstname == undefined) {
                    return (
                        item?.customer?.first_name.toLowerCase().includes(searchString) || item?.orderNo.includes(searchString)
                    )
                } else {
                    return (
                        item?.customer?.firstname.toLowerCase().includes(searchString) || item?.orderNo.includes(searchString)
                    )
                }
            });
            if (filterOrders) {
                setFilteredOrders(filterOrders);
                setcurrentPage(1);
            } else {
                handleCallback(0);
            }
        } else {
            handleCallback(0);
        }
    }
    const filterByStatus = (e) => {
        const keyword = e.target.value;
        if (keyword == "all") {
            window.location.reload();
        }
        else {
            if (keyword != '') {
                var filteredUsers = get_data.filter(item => {
                    const query = keyword.toLowerCase();
                    if (item.status != null && item.status != undefined) {
                        return (
                            item.status.order_status[0].toLowerCase().startsWith(keyword.toLowerCase())
                        )
                    }
                });
                setFilteredOrders(filteredUsers);
                setcurrentPage(1);
            }
            else {
                window.location.reload();
            }
        }
    }
    const notify = (message) => {
        toast.success(message)
    }
    const errornotify = (message) => {
        toast.error(message)
    }
    const changeDriverSideStatus = async (orderId, status) => {
        await apiRequest({ id: orderId, driver_side_status: status }, Url.BASE_URL + '/Orders', 'patch');
    }
    const _onChangeDropdown = async (type, text, orderId) => {
        var orderCustomerData = await apiRequest({}, Url.BASE_URL + '/Orders/' + orderId + '?filter={"include":"customer"}', 'get');
        var projectSettings = await apiRequest([], Url.BASE_URL + '/projectSettings/', 'get');
        if (projectSettings.data.extracted[0].countryCode != null) {
            setCountryCode(projectSettings.data.extracted[0].countryCode);
        }
        var customerMobile = orderCustomerData?.data?.customer?.mobile;
        if (text == "Cancelled") {
            let update_status = {
                "orderId": orderId,
                "order_status": ["Cancelled"]
            }
            await apiRequest({
                notification_number: "0008",
                orderId: orderId
            }, Url.BASE_URL + '/loopback-notification', 'post');
            await changeDriverSideStatus(orderId, "cancelled");
            await apiRequest(update_status, Url.UPDATEORDERSTATUS, 'patch');
            await EmailTemplates.orderCancelled_Admin(orderId);
            await apiRequest({
                to: country_code + customerMobile,
                content: "Dear Customer, Your order has been cancelled by the restaurant!! Delivery App"
            }, Url.BASE_URL + '/send-sms', 'post');
        } else if (text == "Accepted") {
            await apiRequest({
                notification_number: "0004",
                orderId: orderId
            }, Url.BASE_URL + '/loopback-notification', 'post');
            await apiRequest({
                to: country_code + customerMobile,
                content: "Dear Customer, Your order has been accepted by the restaurant!! Delivery App"
            }, Url.BASE_URL + '/send-sms', 'post');
            setStatusus(text)
        } else if (text == "Prepared") {
            await apiRequest({
                notification_number: "0003",
                orderId: orderId
            }, Url.BASE_URL + '/loopback-notification', 'post');
            await apiRequest({
                to: country_code + customerMobile,
                content: "Dear Customer, Your order is prepared by the restaurant, It will be soon delivered to your address. Delivery App."
            }, Url.BASE_URL + '/send-sms', 'post');
            setStatusus(text)
        }
        else if (text == "Preparing") {
            await apiRequest({
                notification_number: "0005",
                orderId: orderId
            }, Url.BASE_URL + '/loopback-notification', 'post');
            setStatusus(text)
        }
        if (type === 'value') {
            if (text !== '') {
                let status = []
                status.push(text)
                let update_status = {
                    "orderId": orderId,
                    "order_status": status,
                    "status": [1],
                    "active": ["active"]
                }
                let location_response = await apiRequest(update_status, Url.UPDATEORDERSTATUS, 'post')
                if (location_response.error) {
                    errornotify("'Failed', response.error.message");
                    return;
                } else {
                    status = []
                    let upder_order = {
                        "id": orderId,
                        "orderstatusId": location_response.data.data.id
                    }
                    let update_order_response = await apiRequest(upder_order, Url.UPDATEORDERS, 'patch')
                    notify("Updated successfully");
                    window.location.reload();
                }
            }
        }
    }
    const handleNextbtn = () => {
        setcurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };
    const handlePrevbtn = () => {
        setcurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };
    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
    }
    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
    }
    const handleLoadMore = () => {
        setitemsPerPage(itemsPerPage + 5);
    };
    const handleManualClose = () => {
        window.location.reload();
    }
    const handleOnSearch = (string, results) => {
        if (selected_customer.email == '' || selected_customer.mobile == '') {

        }
        if (string == [] || results == []) {

        }
    }
    const handleOnSearch2 = (string, results) => {
        if (string == [] || results == []) {
            setCustomerReadOnly(false);
            setProductBlockStyle({ "pointer-events": "none" });
            setHelpText("Please fill the customer details on left first and then search products above");
            setSelectedCustomer({ first_name: '', firstname: '', last_name: '', lastname: '', email: '', mobile: '' });
            setCardHolderName('');
        }
    }
    const clearData = () => {
        setCartData([]);
        setAmounts({
            item_total: 0,
            taxes_and_charges: 0,
            delivery_charges: 0,
            grand_total: 0,
            discount_total: 0
        });
    }
    const handleOnHover = (result) => {

    }
    const handleOnSelectCustomer = (item) => {
        var firstName = item.firstname ? item.firstname : item.first_name;
        var lastName = item.lastname ? item.lastname : item.last_name;
        setSelectedCustomer(item);
        if (pickup_location != "") {
            setProductBlockStyle({ "pointer-events": "" });
            setHelpText("Search products and add to the order, so that they will appear here.");
        }
        setCardHolderName(firstName + " " + lastName);
        setCustomerReadOnly(true);
    }
    const handleOnSelectProduct = async (item) => {
        item.show = true;
        setSelectedProduct(item);
        var addons = await apiRequest({
            product: item.value
        }, Url.BASE_URL + '/addon-categories', 'post');
        var addonsData = [];
        for (var i = 0; i < addons?.data?.models?.length; i++) {
            addons.data.models[i].checked = false;
            if (item.value == addons?.data?.models[i]?.product && addons.data.models[i].addOns.length > 0) {
                addonsData.push(addons?.data?.models?.[i]);
            }
        }
        setSelectedAddOns(addonsData);
    }
    const handleOnFocus = () => {

    }
    const formatResult = (item) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left', fontSize: 13 }}>{item.label}</span>
            </>
        )
    }
    const incrementCart = (productId) => {
        for (var i = 0; i < cartData.length; i++) {
            if (cartData[i].id == productId) {
                cartData[i].quantity = cartData[i].quantity + 1;
                cartData[i].itemTotal = cartData[i].quantity * cartData[i].price;
            }
        }
        setCartData([...cartData]);
        Amounts();
    }
    const decrementCart = (productId) => {
        for (var i = 0; i < cartData.length; i++) {
            if (cartData[i].id == productId) {
                if (cartData[i].quantity == 1) {
                    return false;
                } else {
                    cartData[i].quantity = cartData[i].quantity - 1;
                    cartData[i].itemTotal = cartData[i].quantity * cartData[i].price;
                }
            }
        }
        setCartData([...cartData]);
        Amounts();
    }
    const updateItemToCart = (item) => {
        for (var i = 0; i < cartData.length; i++) {
            if (cartData[i].id == item.id) {
                cartData[i] = item;
                selected_product.show = false;
            }
        }
        setSelectedProduct({
            show: false
        })
        setCartData(cartData);
        Amounts();
    }
    const deleteProductFromCart = (index) => {
        cartData.splice(index, 1);
        setCartData([...cartData]);
        if (cartData.length == 0) {
            setPaymentHelpText("Please add at least one item to cart and then fill the payment information.");
            setPaymentBlockStyle("none");
        }
        Amounts();
    }
    const ChangeAddOnsHandler = (product, addonid, price, checkedFlag, maxAllowedAddons, className, addonidAttr) => {
        var inputElements = [].slice.call(document.querySelectorAll('.' + className));
        var checkedValue = inputElements.filter(chk => chk.checked).length;
        if (checkedValue > maxAllowedAddons) {
            alert("Maximum allowed addons reached!!");
            document.getElementById(addonidAttr).checked = false;
            return false;
        }
        for (var i = 0; i < product.addOns.length; i++) {
            if (product.addOns[i].id == addonid && product.addOns[i].required == true) {
                product.addOns[i].checked = true;
            }
        }
        if (checkedFlag == true) {
            product.price = product.price + price;
        } else {
            product.price = product.price - price;
        }
        setSelectedProduct(product => ({
            ...product
        }));
    };
    return (
        <div>
            <html lang="en">
                <ToastContainer />
                <div class="sticky">
                    <Header parentCallback={(params) => handleCallback(params)} />
                </div>
                <body>
                    <div class="middle_block">
                        <Sidebar />
                        <div class="main_content">
                            <div class="container-fluid">
                                <div class="order_page">
                                    <h2 class="head_sty1">Orders</h2>
                                    <OrderFilters values={
                                        {
                                            serachedname: serachedname,
                                            filter: filterByNameOrId,
                                            startDate: startDate,
                                            endDate: endDate,
                                            getOrders: getOrders,
                                            setDateRange: setDateRange,
                                            filterByStatus: filterByStatus,
                                            setManualOrderClose: setManualOrderClose,
                                            dateRange: dateRange,
                                            get_orders_data: get_orders_data,
                                            setOrders: setOrders,
                                            filterDateRange: filterDateRange
                                        }
                                    } />
                                    {filtered_orders?.length > 0 ? renderData(currentItems) : ''}
                                </div>
                            </div>
                            <OrdersETA values={{
                                show: show,
                                handleClose: handleClose,
                                eta: eta,
                                setEta: setEta
                            }} />
                            <Modal style={{ "body": { "background-color": "#fff !important" } }} class="modalform" size="lg" class="modal-dialog modal-dialog-centered modal-lg" style={{ 'width': '1000px;', 'padding-top': '7px' }} fullscreen='true' show={(selected_product?.show != false) ? true : false} onHide={() => setSelectedProduct(prevState => ({
                                ...prevState,
                                show: false
                            }))}>
                                <div class="modal-content">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Product Information</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <img style={{ "width": "100px", "height": "100px" }} src={selected_product?.image} />
                                            </div>
                                            <div className="col-md-9">
                                                <h5>
                                                    {selected_product?.label}
                                                </h5>
                                                <div style={{ "display": "flex" }}>
                                                </div>
                                            </div>
                                            <div style={{ "margin": "10px" }} class="col-md-12">
                                                <h5>Add On</h5>
                                                <p>Select upto one options</p>
                                            </div>
                                            <hr style={{ "width": "100%", "border": "1px solid #e2dddd99" }} />
                                            {
                                                selected_addons.length > 0
                                                    ? selected_addons?.map((item1, index1) => {
                                                        var classNameForAddons = `addons${item1.id}`;
                                                        return (
                                                            <div class="col-md-12">
                                                                <b>{(item1.addOns.length > 0) ? item1?.name : ''}</b>  <i>{"(Maximum " + item1?.max_addons_allowed + " allowed)"}</i>
                                                                <div class="row">
                                                                    {
                                                                        item1.addOns.length > 0
                                                                            ? item1.addOns?.map((item2, index2) => {
                                                                                var addonidAttr = `addonid${item2.id}`;
                                                                                if (item2.required == false) {
                                                                                    item2.checked2 = true;
                                                                                }
                                                                                return (
                                                                                    <div class="col-md-4">
                                                                                        <div style={{ "display": "flex" }}>
                                                                                            <p>{item2.name}</p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{global_currency}<p>{item2.price}</p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                            <input onChange={(e) => ChangeAddOnsHandler(selected_product, item2.id, item2.price, e.target.checked, item1?.max_addons_allowed, classNameForAddons, addonidAttr)}
                                                                                                checked={item2.price != 0 ? item2.checked : true}
                                                                                                className={classNameForAddons}
                                                                                                id={addonidAttr}
                                                                                                disabled={item2.checked2} style={{ "margin-top": "-15px" }} type="checkbox" />

                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            : null
                                                                    }
                                                                </div>
                                                                <hr style={{ "width": "100%", "border": "1px solid #e2dddd99" }} />
                                                            </div>
                                                        )
                                                    }) : null
                                            }
                                            <div style={{ "margin": "8px", "display": "flex" }} class="col-md-12">
                                                <div class="col-md-3">
                                                    <div style={{ "width": "100px", "display": "flex", "border": "2px solid #cccccc", "justify-content": "center", "border-radius": "4px", "height": "30px", "background": "#fff" }}>
                                                        <a onClick={() => {
                                                            if (selected_product?.quantity == 1) {
                                                                return false;
                                                            } else {
                                                                setSelectedProduct(prevState => ({
                                                                    ...prevState,
                                                                    quantity: selected_product?.quantity - 1
                                                                }));
                                                            }
                                                        }} style={{ "margin": "0px 8px 2px 8px", "font-size": "18px", "cursor": "pointer" }}>-</a>
                                                        <a style={{ "margin": "4px 8px 2px 8px", "font-size": "14px" }}>{selected_product?.quantity}</a>
                                                        <a onClick={() => {
                                                            setSelectedProduct(prevState => ({
                                                                ...prevState,
                                                                quantity: selected_product?.quantity + 1
                                                            }));
                                                        }} style={{ "margin": "0px 8px 2px 8px", "font-size": "18px", "cursor": "pointer" }}>+</a>
                                                    </div>
                                                </div>
                                                <div class="col-md-9">
                                                    <button onClick={() => {
                                                        if (selected_product.edit != true) {
                                                            addItemToCart(selected_product)
                                                        } else {
                                                            updateItemToCart(selected_product);
                                                        }
                                                    }} style={{ "padding": "3px", "color": "#fff", "width": "300px", "border-radius": "4px", "background-color": "#319f60" }}>Add Item -  {global_currency + selected_product?.price}</button>
                                                </div>
                                            </div>
                                        </div><br /><br />
                                    </Modal.Body>
                                </div>
                            </Modal>
                            <div id="add-order-modal">
                                <Modal align="center" size="s" className="custom-order-popup" class="modalform modal-dialog modal-dialog-centered" fullscreen='true' show={manual_order_show} onHide={handleManualClose}>
                                    <div class="w">
                                        <div class="modal-content manual_order_mc">
                                            <Modal.Header closeButton>
                                                <Modal.Title>Add Order</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="mdl_order_body">
                                                <div class="wrapperr">
                                                    <div class="row">
                                                        <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                                                            <form>
                                                                <h1 class="manual_order_titles">
                                                                    <i class="fas fa-shipping-fast"></i>
                                                                    Customer Details
                                                                </h1><br /><br />
                                                                <div class="top-z">
                                                                    <label class="mo_label_title" for="">Search Customer</label><br />
                                                                    {
                                                                        customerDropdown.length > 0 ? <ReactSearchAutocomplete
                                                                            items={customerDropdown ? customerDropdown : [
                                                                            ]}
                                                                            onSearch={handleOnSearch2}
                                                                            onHover={handleOnHover}
                                                                            onSelect={handleOnSelectCustomer}
                                                                            onFocus={handleOnFocus}
                                                                            autoFocus
                                                                            formatResult={formatResult}
                                                                        /> : ''
                                                                    }

                                                                </div><br />
                                                                <div class="row">
                                                                    <div class="col-sm-12 col-xs-12">
                                                                        <label class="mo_label_title" for="f-name">First Name</label>
                                                                        <input disabled={customer_read_only} onChange={(e) => setSelectedCustomer(prevState => ({
                                                                            ...prevState,
                                                                            first_name: e.target.value
                                                                        }))} style={{ "font-size": "13px !important" }} value={selected_customer.firstname ? selected_customer.firstname : selected_customer.first_name} class="form-control custText mb-1" type="text" name="f-name" />
                                                                    </div>
                                                                    <div class="col-sm-12 col-xs-12">
                                                                        <label class="mo_label_title" for="l-name">Last Name</label>
                                                                        <input disabled={customer_read_only} onChange={(e) => setSelectedCustomer(prevState => ({
                                                                            ...prevState,
                                                                            last_name: e.target.value
                                                                        }))} class="form-control custText" value={selected_customer.lastname ? selected_customer.lastname : selected_customer.last_name} type="text" name="l-name" />
                                                                    </div>
                                                                </div>
                                                                <div class="street">
                                                                    <label class="mo_label_title" for="name">Email</label>
                                                                    <input disabled={customer_read_only} onChange={(e) => {
                                                                        if (pickup_location != "") {
                                                                            setProductBlockStyle({
                                                                                "pointer-events": ""
                                                                            });
                                                                            setHelpText("Search products and add to the order, so that they will appear here.");
                                                                        }
                                                                        setSelectedCustomer(prevState => ({
                                                                            ...prevState,
                                                                            email: e.target.value
                                                                        }))
                                                                    }} class="form-control custText" value={selected_customer?.email} type="text" name="address" />
                                                                </div>
                                                                <div class="street">
                                                                    <label class="mo_label_title" for="name">Mobile</label>
                                                                    <input disabled={customer_read_only} onChange={(e) => {
                                                                        if (e.target.value < 0) {
                                                                            alert("Negative values are not allowed!!");
                                                                            setSelectedCustomer(prevState => ({
                                                                                ...prevState,
                                                                                mobile: ''
                                                                            }))
                                                                            return false;
                                                                        } else {
                                                                            setSelectedCustomer(prevState => ({
                                                                                ...prevState,
                                                                                mobile: e.target.value
                                                                            }))
                                                                        }
                                                                    }} class="form-control custText" value={selected_customer?.mobile} type="number" name="address" />
                                                                </div>
                                                                <hr></hr>
                                                                <h1 class="manual_order_titles">
                                                                    <i class="far fa-credit-card"></i> Delivery Type
                                                                </h1><br /><br />
                                                                {
                                                                    (allow_deliveries == true && allow_pickups == true) ?
                                                                        <div class="address-info">
                                                                            <div style={{ "display": "flex" }}>
                                                                                <input style={{ "min-height": "16px" }} class="form-control" onChange={(e) => {
                                                                                    setDeliverType(e.target.value);
                                                                                    setCustomerNewAddress(true);
                                                                                    clearData();
                                                                                }} type="radio" value="delivery" name="delivery_type" /> <p>Delivery</p>
                                                                            </div>
                                                                            <div style={{ "display": "flex" }}>
                                                                                <input style={{ "min-height": "16px" }} class="form-control" onChange={(e) => {
                                                                                    setDeliverType(e.target.value);
                                                                                    setProductBlockStyle({ "pointer-events": "" });
                                                                                    setCustomerNewAddress(false);
                                                                                    clearData();
                                                                                }} value="pickup" type="radio" name="delivery_type" /> <p>Pickup</p>
                                                                            </div>
                                                                        </div> : ''
                                                                }
                                                                {
                                                                    (allow_deliveries == true && allow_pickups == false) ?
                                                                        <div>
                                                                            <div style={{ "margin-top": "10px" }}>
                                                                                {selected_customer?.addresses?.length > 0 ?
                                                                                    <select onChange={(e) => {
                                                                                        setRestaurantLocation(e.target.value);
                                                                                    }} class="form-control" name="" >
                                                                                        <option value="">Select Customer Address</option>
                                                                                        <option value="new">Add New Address</option>
                                                                                        {selected_customer?.addresses?.map((item, index) => {
                                                                                            return (
                                                                                                <option value={JSON.stringify(item)}>{item.name}</option>
                                                                                            );
                                                                                        })}
                                                                                    </select> :
                                                                                    <input type="text" placeholder="Type Address" name="address" class="form-control" />
                                                                                }
                                                                            </div><br />
                                                                            {
                                                                                (cust_new_address == "new" || cust_new_address == undefined || cust_new_address == "") ? <div class="street">
                                                                                    <label class="mo_label_title" for="name">Add New Customer Address</label>
                                                                                    <input class="form-control" value="" type="text" name="address" />
                                                                                </div> : ''
                                                                            }
                                                                        </div>
                                                                        : ''
                                                                }
                                                                {
                                                                    (allow_pickups == true && allow_deliveries == false) ?
                                                                        <div style={{ "margin-top": "12px" }}>
                                                                            <Select
                                                                                className="pickup_location_info"
                                                                                style={{ "text-align": "left" }}
                                                                                value={pickup_location}
                                                                                onChange={setPickupLocation}
                                                                                options={LocationDropdown}
                                                                            />
                                                                            <div>
                                                                            </div>
                                                                        </div>
                                                                        : ''
                                                                }
                                                                {delivery_type == "delivery" ?
                                                                    <div>
                                                                        <div style={{ "margin-top": "10px" }}>
                                                                            {selected_customer?.addresses?.length > 0 ?
                                                                                <select onChange={(e) => {
                                                                                    setRestaurantLocation(e.target.value);
                                                                                }} class="form-control" name="" >
                                                                                    <option value="">Select Customer Address</option>
                                                                                    <option value="new">Add New Address</option>
                                                                                    {selected_customer?.addresses?.map((item, index) => {
                                                                                        return (
                                                                                            <option value={JSON.stringify(item)}>{item.name}</option>
                                                                                        );
                                                                                    })}
                                                                                </select> :
                                                                                ManualOrder.getPlacesAutoComplete({
                                                                                    searchOptions: searchOptions,
                                                                                    address: address,
                                                                                    setAddress: setAddress,
                                                                                    handleSelect: handleSelect,
                                                                                    setLat: setLat,
                                                                                    setLng: setLng,
                                                                                    coordinates: coordinates
                                                                                })
                                                                            }
                                                                            <Select
                                                                                placeholder="Select Pickup location"
                                                                                className="pickup_location_mo_select"
                                                                                style={{ "text-align": "left" }}
                                                                                value={pickup_location}
                                                                                onChange={setPickupLocation}
                                                                                options={LocationDropdown}
                                                                            />
                                                                        </div><br />
                                                                        {
                                                                            (cust_new_address == "new") ? <div class="street">
                                                                                <label class="mo_label_title" for="name">Add New Customer Address</label>
                                                                                {
                                                                                    ManualOrder.getPlacesAutoComplete({
                                                                                        searchOptions: searchOptions,
                                                                                        address: address,
                                                                                        setAddress: setAddress,
                                                                                        handleSelect: handleSelect,
                                                                                        setLat: setLat,
                                                                                        setLng: setLng,
                                                                                        coordinates: coordinates
                                                                                    })}
                                                                            </div> : ''
                                                                        }
                                                                    </div> :
                                                                    <>
                                                                        {(delivery_type == "pickup")
                                                                            ?
                                                                            <div style={{ "margin-top": "12px" }}>
                                                                                <Select
                                                                                    className="pickup_location_info"
                                                                                    style={{ "text-align": "left" }}
                                                                                    value={pickup_location}
                                                                                    onChange={setPickupLocation}
                                                                                    options={LocationDropdown}
                                                                                />
                                                                                <div>
                                                                                </div>
                                                                            </div>
                                                                            : <>
                                                                            </>
                                                                        }
                                                                        <h6></h6>
                                                                    </>
                                                                }
                                                            </form>
                                                        </div>
                                                        <div class="col-lg-4 col-md-12 col-sm-12 col-12 mo_pd second">
                                                            <form action="">
                                                                <h1 class="manual_order_titles">
                                                                    <i class="fas fa-shipping-fast"></i>
                                                                    Product Details
                                                                </h1><br />
                                                                <div style={product_block_style}>
                                                                    <label style={{ "margin-right": "600px", "width": "115px" }} for="">Search Product</label>
                                                                    <ReactSearchAutocomplete
                                                                        items={productsDropdown}
                                                                        onSearch={handleOnSearch}
                                                                        onHover={handleOnHover}
                                                                        onSelect={handleOnSelectProduct}
                                                                        onFocus={handleOnFocus}
                                                                        autoFocus
                                                                        formatResult={formatResult}
                                                                    />
                                                                </div>

                                                                {
                                                                    cartData.length > 0 ?
                                                                        <div class="table-responsive pt-2"><table className="manual_order_tbl">
                                                                            <tr>
                                                                                <th class="mo_td">Name</th>
                                                                                <th class="mo_td">Quantity</th>
                                                                                <th class="mo_td">Sub Total</th>
                                                                                <th class="mo_td">Total</th>
                                                                                {/* <th class="mo_td">Edit</th> */}
                                                                                <th class="mo_td">Delete</th>
                                                                            </tr>
                                                                            {
                                                                                cartData.map(function (item, index) {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td class="mo_td">{item?.name}</td>
                                                                                            <td class="mo_td"><div style={{ "width": "100px", "display": "flex", "border": "2px solid #cccccc", "justify-content": "center", "border-radius": "4px", "height": "30px", "background": "#fff" }}>
                                                                                                <a onClick={() => decrementCart(item.id)} style={{ "margin": "0px 8px 2px 8px", "font-size": "18px", "cursor": "pointer" }}>-</a>
                                                                                                <a style={{ "margin": "4px 8px 2px 8px", "font-size": "13px" }}>{item?.quantity}</a>
                                                                                                <a onClick={() => incrementCart(item.id)} style={{ "margin": "0px 8px 2px 8px", "font-size": "18px", "cursor": "pointer" }}>+</a>
                                                                                            </div></td>
                                                                                            <td class="mo_td">{global_currency + item?.itemTotal}</td>

                                                                                            <td class="mo_td">{global_currency + item?.itemTotal}</td>
                                                                                            {/* <td class="mo_td"><p onClick={() => handleOnSelectProduct(item)} style={{ "font-size": "13px", "margin-top": "8px", "cursor": "pointer" }}>Edit</p></td> */}
                                                                                            <td class="mo_td"><p onClick={() => deleteProductFromCart(index)} style={{ "font-size": "15px", "margin-top": "8px", "color": "red", "cursor": "pointer" }}>X</p></td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </table></div>
                                                                        :
                                                                        <p>{help_text}</p>
                                                                }
                                                                <h1 class="manual_order_titles">
                                                                    Coupons
                                                                </h1><br /><br />
                                                                <div style={{ "display": "flex" }}>
                                                                    <input style={product_block_style} onChange={(e) => {
                                                                        if (selected_customer.email == "" || selected_customer.mobile == "") {
                                                                            alert("please select customer details before you use the coupons!!")
                                                                            return false;
                                                                        }
                                                                        setPromoCode(e.target.value)
                                                                    }} type="text" value={promocode} class="form-control" />
                                                                    <a onClick={() => validatePromoCode(promocode)} style={{ "margin-left": "-52px", "margin-top": "12px", "color": "#e48293", "cursor": "pointer" }}>Apply</a>
                                                                </div>
                                                                <br></br>
                                                            </form>
                                                        </div>
                                                        <div class="col-lg-4 col-md-12 col-sm-12 col-12 mo_pd second">
                                                            <h1 class="manual_order_titles paymentheading">
                                                                Amount & Taxes
                                                            </h1><br /><br />
                                                            <div style={{ "display": "flex", "justify-content": "space-between", "margin-bottom": "-17px" }}>
                                                                <p>Item Total : </p>
                                                                <p>{global_currency + amounts.item_total.toFixed(2)}</p>
                                                            </div>
                                                            <div style={{ "display": "flex", "justify-content": "space-between", "margin-bottom": "-17px" }}>
                                                                <p>Taxes Total: </p>
                                                                <p>{global_currency + amounts?.taxes_and_charges.toFixed(2)}</p>
                                                            </div>
                                                            <div style={{ "display": "flex", "justify-content": "space-between", "margin-bottom": "-17px" }}>
                                                                <p>Discount Total: </p>
                                                                <p>{global_currency + discount_total.toFixed(2)}</p>
                                                            </div>
                                                            {
                                                                allow_deliveries == true ?
                                                                    <div style={{ "display": "flex", "justify-content": "space-between", "margin-bottom": "-17px" }}>
                                                                        <p>Delivery Charges : </p>
                                                                        <p>{global_currency + amounts?.delivery_charges}</p>
                                                                    </div> : ''
                                                            }
                                                            <div style={{ "display": "flex", "justify-content": "space-between", "margin-bottom": "-17px" }}>
                                                                <p>Net Total : </p>
                                                                <p>{global_currency + amounts.grand_total.toFixed(2)}</p>
                                                            </div>
                                                            <br></br>
                                                            {
                                                                ManualOrder.getPaymentSection({
                                                                    payment_block_style: payment_block_style,
                                                                    card_holder_name: card_holder_name,
                                                                    setCardHolderName: setCardHolderName,
                                                                    setCardCV: setCardCV,
                                                                    setCardExpiry: setCardExpiry,
                                                                    setCardNumber: setCardNumber,
                                                                    payment_help_text: payment_help_text
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer >
                                                <div class="col-md-12">
                                                    <div id="place_order_btn" class="form-group mb-0 staff_profile_btn_group">
                                                        {loading == false ? <button id="" onClick={() => PlaceOrder()} class="btn_sty_1" style={{ "min-width": '110px' }} >Place Order</button> : <button id="" class="btn_sty_1" style={{ "min-width": '110px' }} >Placing Order...</button>}
                                                    </div>
                                                </div>
                                            </Modal.Footer>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                            <Modal class="modalform" size="lg" class="modal-dialog modal-dialog-centered modal-lg" style={{ 'width': '1000px;', 'padding-top': '150px' }} fullscreen='true' show={order_detail} onHide={handleOrderDetailClose}>
                                <div class="modal-content">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Order Details</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div class="modal-body">
                                            <div class="r_order_box">
                                                <div class="r_order_top">
                                                    <div class="img_box"><img src={customer_profile} alt="" /></div>
                                                    <div class="r_order_info">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <h2 class="r_name"><span>{name}</span></h2>
                                                                <h4>Phone: <span>{phone}</span></h4>
                                                                <h4>Email: <span>{email}</span></h4>
                                                                <h4>Type: <span>{type}</span></h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="r_order_bottom">
                                                    <div class="r_dateandtime"><Moment format={global_date ? global_date : " DD MMM  YYYY-h:mm A"}>{ordered_date}</Moment></div>
                                                    <div class="r_eta">
                                                        Order ID: <span>{orderno}</span>
                                                    </div>
                                                    <div class="r_status dropdown">
                                                        <div class="status_selected">{(statusus != null) ? statusus : null}
                                                            <Dropdown onSelect={e => _onChangeDropdown('value', e, orderId)}>
                                                                <Dropdown.Toggle as={CustomToggle} />
                                                                <Dropdown.Menu size="sm" title={statusus} value={statusus} >
                                                                    {/* <Dropdown.Header>Options</Dropdown.Header> */}
                                                                    <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
                                                                    <Dropdown.Item eventKey="Accepted">Accepted</Dropdown.Item>
                                                                    <Dropdown.Item eventKey="Preparing">Preparing</Dropdown.Item>
                                                                    <Dropdown.Item eventKey="Prepared">Prepared</Dropdown.Item>
                                                                    {/* <Dropdown.Item eventKey="On The Way">On The Way</Dropdown.Item> */}
                                                                    <Dropdown.Item eventKey="Cancelled">Cancelled</Dropdown.Item>
                                                                    <Dropdown.Item eventKey="Delivered">Delivered</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="odr_details_product">
                                                <h3 class="oder_popup_headings">Products</h3>
                                                {
                                                    products?.length > 0
                                                        ? products?.map((item, index) => {
                                                            return (
                                                                <div class="r_order_box r_order_box_product">
                                                                    <div class="r_order_top">
                                                                        <div class="img_box"> {(item.product.image != null) ? <img src={item.product.image} alt="" /> : <img src={'./img-placeholder.png'} alt="" />}</div>
                                                                        <div class="r_order_info">
                                                                            <div class="row">
                                                                                <div class="col-md-12">
                                                                                    <h2 class="r_name">{item.product.name}</h2>
                                                                                </div>
                                                                                {
                                                                                    (statusus == "Pending" || statusus == "Accepted" || driver_side_status == "waiting") ?
                                                                                        <div onClick={() => handleEditOrderShow(item)} class="col-md-6">
                                                                                            <h4><span style={{ "margin-right": "10px" }}>Quantity: {item.quantity}</span> <a href="#" style={{ "color": "#319F60s" }}><i class="icofont-pencil-alt-2"></i> Edit</a></h4>
                                                                                        </div> : <div class="col-md-6">
                                                                                            <h4><span style={{ "margin-right": "10px" }}>Quantity: {item.quantity}</span> <a href="#" style={{ "color": "#319F60s" }}></a></h4>
                                                                                        </div>
                                                                                }

                                                                                <div class="col-md-6 r_align_right">
                                                                                    <h3><span style={{ "color": "#319F60", "font-weight": "600" }}>{global_currency + item.product_price * item.quantity}</span></h3>
                                                                                </div>
                                                                                <div class="col-md-12">
                                                                                    <p>Notes: Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="r_order_bottom">
                                                                        {
                                                                            item.addOns != undefined && item.addOns != null && item.addOns.length > 0
                                                                                ? item.addOns?.map((item, index) => {
                                                                                    return (
                                                                                        <ul class="order_addontag">
                                                                                            <li>{item.name} {global_currency + item.price}</li>
                                                                                        </ul>
                                                                                    )
                                                                                })
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        : <div align="center" style={{ 'font-size': '16' }} >{view_show_msg}</div>
                                                }
                                            </div>
                                            <div class="ord_summary">
                                                <h3 class="oder_popup_headings">Summary</h3>
                                                <div class="r_order_box r_order_summary">
                                                    <div class="ord_box_wrap">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="r_summary_info">
                                                                    <span>Subtotal: </span> <span><strong>{global_currency + item_total}</strong></span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="r_summary_info">
                                                                    <span>Tax: </span> <span><strong>{global_currency + tax_total}</strong></span>
                                                                </div>
                                                            </div>
                                                            {
                                                                order_type == "delivery" ? <div class="col-md-6">
                                                                    <div class="r_summary_info">
                                                                        <span>Delivery Charge: </span> <span><strong>{global_currency + delivery_amount}</strong></span>
                                                                    </div>
                                                                </div> : ""
                                                            }

                                                            <div class="col-md-6">
                                                                <div class="r_summary_info">
                                                                    <span>Offers: </span> <span><strong>{offer_amount == undefined ? global_currency + "0.00" : global_currency + offer_amount}</strong></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="r_order_bottom" style={{ "display": "block" }}>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="r_summary_info" style={{ "padding": "0" }}>
                                                                    <span>Total: </span> <span><strong style={{ "color": "#000", "font-size": "18px" }}>{global_currency + parseFloat(order_total).toFixed(2)}</strong></span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="r_summary_info payment_method" style={{ "padding": "0" }}>
                                                                    <span>Payment Method : </span> <span><strong> &nbsp;Card</strong></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                order_type == "delivery" ? <div class="ord_delivery_info">
                                                    <h3 class="oder_popup_headings">Delivery Information</h3>
                                                    <div class="r_order_box r_order_summary">
                                                        <div class="ord_box_wrap">
                                                            <div class="row">
                                                                <div class="col-md-4">
                                                                    {
                                                                        type == "delivery" ?
                                                                            <div class="pro_stats_box">
                                                                                <h3>Delivery Address</h3>
                                                                                <p><strong> </strong>{address_name}</p>
                                                                            </div> : ""
                                                                    }

                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="pro_stats_box">
                                                                        <h3>Delivery Instructions</h3>
                                                                        <p>{instructions}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="pro_stats_box">
                                                                        <h3>Drop Off Image</h3>
                                                                        {dropoff_image != undefined && dropoff_image != '' ? <div class="img_box">
                                                                            <img onClick={() => showDropOffImage(dropoff_image ? dropoff_image : "./img-placeholder.png")} src={dropoff_image ? dropoff_image : "./img-placeholder.png"} />
                                                                        </div> : ""}

                                                                    </div>
                                                                </div>
                                                                {/* <div class="col-md-4">
                                                                    <div class="pro_stats_box">
                                                                        <h3>Delivery Schedule</h3>
                                                                        <p> <Moment format={global_date ? global_date : " DD MMM  YYYY-h:mm A"}>{sheduled_date}</Moment></p>
                                                                    </div>
                                                                </div> */}
                                                                {/* <div class="col-md-12">
                                                                    <div class="pro_stats_box" style={{ "margin-bottom": 0 }}>
                                                                        <h3>Additional Notes</h3>
                                                                        <p>{note}</p>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : ""
                                            }
                                            {
                                                (driverInfo == undefined || driverInfo == "" || driverInfo == {} || driverInfo == [])
                                                    ?
                                                    ""
                                                    :
                                                    <div class="ord_driver_info">
                                                        <h3 class="oder_popup_headings">Driver Information</h3>
                                                        <div class="r_order_box r_order_summary">
                                                            <div class="ord_box_wrap">
                                                                <div class="driver_top_info">
                                                                    <div class="driver_pic">
                                                                        <img src={driverInfo?.profile_image} alt="" />
                                                                    </div>
                                                                    <div class="driver_content">
                                                                        <h3>{driverInfo?.first_name}</h3>
                                                                        {statusus == 'Delivered' ? <p>Tip Received: <strong>{tipData == undefined ? "0" : global_currency + tipData}</strong></p> : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </Modal.Body>
                                </div>
                            </Modal>
                            <Modal class="modalform" size="s" class="modal-dialog modal-dialog-centered modal-lg" style={{ 'padding-top': '150px' }} fullscreen='true' show={edit_order} onHide={handleEditOrderClose}>
                                <OrdersEdit values={{
                                    orderId: orderId,
                                    order_detail_id: order_detail_id,
                                    product_image: product_image,
                                    product_name: product_name,
                                    product_price: product_price,
                                    decrementQuantity: decrementQuantity,
                                    product_quantity: product_quantity,
                                    incrementQuantity: incrementQuantity,
                                    updateOrderQuantity: updateOrderQuantity,
                                    inc_dec_buttons: inc_dec_buttons
                                }} />
                            </Modal>
                            <Modal class="modalform" size="s" class="modal-dialog modal-dialog-centered modal-lg" style={{ 'padding-top': '150px' }} fullscreen='true' show={assign_driver} onHide={handleAssignDriverClose}>
                                <div class="modal-content">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Available Drivers</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OrdersAvailableDrivers drivers={drivers_data} handleCheckbox={handleDriverCheckbox} />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <div class="col-md-12">
                                            <div class="form-group mb-0 staff_profile_btn_group">
                                                <button type="submit" class="btn_sty_1" style={{ "width": '110px;' }} onClick={() => assignOrders()} >Assign Orders</button>
                                            </div>
                                        </div>
                                    </Modal.Footer>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </body >
            </html >
            <ul className="pageNumbers">
                <li onClick={handlePrevbtn}
                    className={currentPage == pages[0] ? "disallow" : "allow"}>
                    {"<"}
                </li>
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                <li onClick={handleNextbtn}
                    className={currentPage == pages[pages.length - 1] ? "disallow" : "allow"}>
                    {">"}
                </li>
            </ul>
        </div >
    )
}
export default Orders;